/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Hardware Test authentication hook
 * @author Spencer Eanes & Razi Rais
 * @module Epic.VideoApp.Hooks.UseStoreHardwareTest
 */
import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useRef } from "react";
import {
	afterRetrieveAccessToken,
	useDisconnect,
	useHardwareTestResult,
	useVideoCallAuthentication,
} from "~/hooks";
import { IAccessTokenUpdate, IClientToken, IHardwareTestResult, QueryParameters } from "~/types";
import { hoursToMs, secondsToMs } from "~/utils/dateTime";
import { extractClientTokenFromResponse, setSessionCookies } from "~/utils/jwt";
import { debug } from "~/utils/logging";
import { makeRequest } from "~/utils/request";
import { VideoSessionContext } from "~/web-core/components";
import { ISession } from "~/web-core/interfaces";
import { combinedActions, useAuthState, useRoomState } from "../state";
import { useCaseInsensitiveSearchParam } from "./useSearchParams";

/**
 * Makes a server request with local track data from redux store
 * @param isVideoCall whether or not the hardware test is being stored for a video call
 */
export function useStoreHardwareTest(isVideoCall: boolean): void {
	const clientToken = useAuthState((selectors) => selectors.getClientToken(), []);
	const sessionID = useCaseInsensitiveSearchParam(QueryParameters.sessionId) ?? "";
	const { session } = useContext(VideoSessionContext);
	const isDisconnecting = useRoomState((selectors) => selectors.getIsDisconnecting(), []);
	const hasRefreshToken = useAuthState((selectors) => selectors.getRefreshTokenTimer(), []) !== null;
	const currentHardwareTest: IHardwareTestResult | null = useHardwareTestResult(isVideoCall);
	const dispatch = useDispatch();

	const referenceToken = useRef<IClientToken | null>(clientToken);
	const refSession = useRef<ISession | null>(session ?? null);
	const refHasRefreshToken = useRef<boolean>(hasRefreshToken);

	const requestInProgress = useRef(false);
	const queuedResult = useRef<IResultStore | null>(null);

	// Update the access token reference. This allows us to reference the token below
	// where we POST hardware tests, without re-rendering when it changes
	useEffect(() => {
		referenceToken.current = clientToken;
	}, [clientToken]);

	useEffect(() => {
		refSession.current = session ?? null;
	}, [session]);

	useEffect(() => {
		refHasRefreshToken.current = hasRefreshToken;
	}, [hasRefreshToken]);

	const disconnect = useDisconnect();
	const disconnectRef = useRef(disconnect);
	useEffect(() => {
		disconnectRef.current = disconnect;
	}, [disconnect]);

	const { updateAccessToken } = useVideoCallAuthentication();

	// actually store hardware test result
	const storeHardwareTest = useCallback(
		(result: IResultStore, overrideJWT?: IClientToken): void => {
			// when called after waiting for another request to return the new JWT is passed directly because state will not have updated yet
			const token: IClientToken | null = overrideJWT || referenceToken.current;

			// if there is not valid session or we're disconnecting, don't attempt to log to the server
			if (!token || isDisconnecting) {
				return;
			}

			// after storing a hardware test, store the most recent result that occurred while the request was in flight
			const onRequestComplete = (
				ignoreQueuedResult: boolean = false,
				clientToken?: IClientToken,
			): void => {
				requestInProgress.current = false;
				if (queuedResult.current) {
					// Clear out the previous queued value to ensure we don't end up looping an additional time here
					const queuedValue = queuedResult.current;
					queuedResult.current = null;
					if (!ignoreQueuedResult) {
						storeHardwareTest(queuedValue, clientToken);
					}
				}
			};

			requestInProgress.current = true;
			if (isVideoCall) {
				storeVideoCallHardwareTestResult(result, token)
					.then((response: IAccessTokenUpdate) => {
						let ignoreQueuedResult = false;
						if (!response && !refSession.current) {
							//If there is a problem with Web PACS Auth, we will get null response
							disconnectRef.current(true);
							//Since we are disconnecting, there is no need to process queued result, otherwise it may capture auth attempt failure metric again.
							ignoreQueuedResult = true;
						}
						const {
							expirationSeconds,
							hasRefreshToken,
							clientConfiguration,
							encryptedConfiguration,
						} = response;
						let expirationInstant = null;
						if (expirationSeconds) {
							expirationInstant = Date.now() + secondsToMs(expirationSeconds);
							if (!hasRefreshToken) {
								expirationInstant += hoursToMs(3); //workaround for now to allow 4-hour calls before refresh tokens are enabled
							}
						}

						const tokenUpdate = extractClientTokenFromResponse(response);
						// indicate that the user can now connect to their room, including update their clientToken if we got a new one (only happens when the access token is acquired)
						dispatch(combinedActions.setCanConnect(tokenUpdate ?? null));
						// Web Pacs launches will call get configuration while doing access token exchange after passing the hardware test
						dispatch(combinedActions.setConfiguration(clientConfiguration));
						// update the clientToken cookie if we return a new one (only happens when the access token is acquired)
						if (tokenUpdate) {
							setSessionCookies(
								tokenUpdate,
								sessionID,
								expirationInstant ?? Date.now(),
								hasRefreshToken,
								encryptedConfiguration,
							);
							if (!refHasRefreshToken.current && hasRefreshToken) {
								refHasRefreshToken.current = true;
								afterRetrieveAccessToken(response, updateAccessToken, sessionID, dispatch);
							}
						}
						onRequestComplete(ignoreQueuedResult, tokenUpdate ?? undefined);
					})
					.catch(() => {
						let ignoreQueuedResult = false;
						if (!refSession.current) {
							// TODO: Remove disconnection check once we deprecate web pacs.
							// We should only disconnect when we get an error as part of Web Pacs Authentication, which happens when storing hardware tests outside of the VideoRoom
							disconnectRef.current(true);
							//Since we are disconnecting, there is no need to process queued result, otherwise it may capture auth attempt failure metric again.
							ignoreQueuedResult = true;
						}
						onRequestComplete(ignoreQueuedResult);
					});
			} else {
				storePrevisitHardwareTestResult(result, token)
					.then(() => onRequestComplete())
					.catch(() => {
						disconnectRef.current(true);
					});
			}
		},
		[sessionID, dispatch, isVideoCall, isDisconnecting, updateAccessToken],
	);

	// make the request to store a hardware test if no request is in flight, otherwise save the result to store when the first request completes
	const tryStoreHardwareTest = useCallback(
		(result: IResultStore): void => {
			if (requestInProgress.current) {
				queuedResult.current = result;
			} else {
				storeHardwareTest(result);
			}
		},
		[storeHardwareTest],
	);

	useEffect(() => {
		// Do not attempt to log an incomplete hardware test
		if (!currentHardwareTest) {
			return;
		}

		// If there is not valid session, don't attempt to log to the server
		if (!referenceToken.current) {
			return;
		}

		// Wait to log connection results until we can properly update the JWT in cookies
		if (isVideoCall && !sessionID) {
			return;
		}

		debug("Success: ", currentHardwareTest.success);
		debug("Should log: ", currentHardwareTest.shouldLogToEpic);
		debug("Microphone Track: ", currentHardwareTest.microphone);
		debug("Video Track: ", currentHardwareTest.camera);
		debug("Speaker Track: ", currentHardwareTest.speaker);
		debug(
			`Twilio Error - ${currentHardwareTest.errorCode?.toString()} : ${
				currentHardwareTest.errorMessage
			}`,
		);
		debug("User Language: ", currentHardwareTest.userLanguage);
		debug("\n\n");

		//called this way to pass unit test
		const requestBody = exportFunctions.createRequestBody(
			currentHardwareTest,
			refSession.current?.connectionStatus === "connected",
		);
		tryStoreHardwareTest(requestBody);
	}, [sessionID, currentHardwareTest, isVideoCall, tryStoreHardwareTest]);
}

/**
 * Constructs a request data object in the format the server api expects
 */
function createRequestBody(test: IHardwareTestResult, sentFromCall: boolean): IResultStore {
	return { ...test, sentFromCall };
}

/**
 * This is needed for unit tests to not fail
 * Javascript compilation says createBodyRequest is not called
 * unless it is exported and called this way
 */
const exportFunctions = {
	createRequestBody,
	useStoreHardwareTest,
};

export default exportFunctions;

/**
 * Json Body for storing hardware test results
 */
interface IResultStore extends IHardwareTestResult {
	sentFromCall: boolean;
}

/**
 * Stores the standalone hardware test to the server
 */
async function storePrevisitHardwareTestResult(result: IResultStore, token: IClientToken): Promise<void> {
	return makeRequest("/api/HardwareTest/StoreTestModeResult", "POST", token, result);
}

/**
 * Stores the hardware test result and retrieves an access token.
 */
async function storeVideoCallHardwareTestResult(
	result: IResultStore,
	token: IClientToken,
): Promise<IAccessTokenUpdate> {
	return makeRequest<IAccessTokenUpdate>("/api/HardwareTest/StoreVideoCallResult", "POST", token, result);
}
