/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file wrapper around fetch for common patterns
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.Request
 */

import { AccessTokenType, IClientToken } from "~/types";
import { makeLink } from "./general";
import { addLogEntry, debug, debugNoFlush } from "./logging";

type Method = "GET" | "POST";
type ContentType = "application/octet-stream" | "image/png" | "application/json";
type AuthHeader = "Authorization" | "x-access-token";

/**
 * Type used to return the status and statusText from the response
 * with the error when a request fails.
 */
export class ResponseError extends Error {
	status: number;
	statusText: string;

	constructor(message: string, response: Response) {
		super(message);
		this.status = response.status;
		this.statusText = response.statusText;
	}
}

/**
 * Type used to pass back extra information about an error encountered in an api call
 * Data returned has to be cast to T, so take caution when accessing that data
 */
export class ApiError<T> extends ResponseError {
	data: T;

	constructor(message: string, data: T, response: Response) {
		super(message, response);
		this.data = data;
	}
}

/**
 *  @param queryStringData request data to be included in the query string
 *  @param keepalive if the request needs to
 */
interface IOptionalRequestParams {
	queryStringData?: Record<string, any>;
	keepalive?: boolean;
	contentType?: ContentType;
}

/**
 * Wrapper around fetch to include standard request formatting and debug logging
 *
 * @param path URL to make the request to
 * @param method request method (GET, POST, etc)
 * @param clientToken - The session token for calling web APIs
 * @param requestBodyData data of the request to be serialized in the body (JSON) or sent as bytes (Arraybuffer)
 * @param additionalData optional parameters not needed in many requests (see IOptionalRequestParams for more information)
 * @returns - The JSON data from the web request
 */
export async function makeRequest<TResp extends Record<string, any> | void, TError = undefined>(
	path: string,
	method: Method,
	token: IClientToken | null,
	requestBodyData?: Record<string, any> | ArrayBuffer,
	additionalData?: IOptionalRequestParams,
): Promise<TResp> {
	let body: string | ArrayBuffer | null = null;
	const headers: Record<string, string> = {};
	let mode: RequestMode | undefined = undefined;

	if (token) {
		headers[getRequestHeaderKey(token.type)] = `Bearer ${token.jwt}`;
	}

	// Parse optional parameters
	const queryStringData = additionalData?.queryStringData;
	const keepalive = additionalData?.keepalive;

	const url = buildRequestURL(path, queryStringData);

	if (url.startsWith("/")) {
		// Only same server paths will start with /
		// without specifying the request is same-origin, using keepalive results in a "Preflight request for request with keepalive specified is currently not supported" error.
		// https://stackoverflow.com/questions/55737748/preflight-request-failing-on-calling-a-post-request-with-keepalive-using-fetch-a
		mode = "same-origin";
	} else {
		mode = "cors";
	}

	// Only send body data as part of a POST request
	if (method === "POST" && requestBodyData) {
		// Check if we should send a raw data or JSON formatted data in our request
		const bytes = requestBodyData as ArrayBuffer;
		if (bytes.byteLength !== undefined) {
			headers["Content-Type"] = additionalData?.contentType ?? "application/octet-stream";
			body = bytes;
		} else {
			headers["Content-Type"] = "application/json";
			body = JSON.stringify(requestBodyData);
		}
	}

	const settings: RequestInit = { method, headers, body, keepalive: keepalive, mode };

	// Perform and log the outbound request
	return handleRequestCore<TResp, TError>(url, settings);
}

/**
 * Performs the request for a formatted url and logs its occurrence and when it finished.
 *
 * @param requestURL - The url with any query data appended
 * @param settings - The request metadata we need to make a successful request
 * @returns - Any JSON data from the web request, throws errors on failure.
 */
async function handleRequestCore<TResp extends Record<string, any> | void, TError = undefined>(
	requestURL: string,
	settings: RequestInit,
): Promise<TResp> {
	// log the request start (avoid logging the url's query parameters)
	const logUrl = requestURL.replace(/[?].*/, "");
	logRequest({ type: "request", method: settings.method, url: logUrl });

	const response = await fetch(requestURL, settings);

	// log the request finishing
	const contentType = response.headers.get("content-type");
	logRequest({
		type: "response",
		method: settings.method,
		url: logUrl,
		successful: response.ok,
		status: response.status,
		statusText: response.statusText,
		contentType: contentType,
	});

	if (!response.ok) {
		// if there was JSON returned with the error try to parse it (used to inform error strings in useVideoCallAuthentication)
		let data: TError | undefined;
		try {
			const text = await response.text();
			data = JSON.parse(text) as TError;
		} catch {
			// we can just throw a generic error when there's no data or we fail to parse it
		}

		const message = `API call to '${logUrl}' failed: ${response.status} - ${response.statusText}`;

		addLogEntry("error", message);
		throw data ? new ApiError<TError>(message, data, response) : new ResponseError(message, response);
	}

	let responseJson: any = null;
	if (contentType?.includes("application/json")) {
		// casting doesn't do anything at runtime, but will avoid linter issues
		responseJson = (await response.json()) as TResp;
	} else if (response.status !== 204) {
		// throw an error when the request should have returned something (not 204 no content)
		throw new ResponseError("API return not JSON", response);
	}

	// responseJson being any makes it so making the request Promise<void> will work with the linter
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return responseJson;
}

function getRequestHeaderKey(type: AccessTokenType): AuthHeader {
	if (type === "vinz") {
		return "Authorization";
	}

	return "x-access-token";
}

/**
 * Build the url with appropriate query string data
 * @param path the request path
 * @param queryData query string data to be included in the URL
 * @returns the full request to be used by fetch
 */
function buildRequestURL(path: string, queryData?: Record<string, any>): string {
	const baseUrl = makeLink(path).replace(/\?+$/g, ""); // remove trailing ?
	const queryString = buildQueryString(queryData);
	if (!queryString) {
		return baseUrl;
	}
	return baseUrl + (baseUrl.includes("?") ? "&" : "?") + queryString;
}

/**
 * Build the query string for the outbound request
 * @param data The parameters to add to the query string
 * @returns The fully built query string to append to the URL
 */
function buildQueryString(data: Record<string, any> = {}): string {
	const queryStr = Object.keys(data)
		.map((key) => `${key}=${encodeURIComponent(data[key])}`)
		.join("&");

	return queryStr;
}

/**
 * The data for a log entry
 */
export interface ILogEntry {
	/** Whether the log entry is an API request or an API response */
	type: "request" | "response";
	/** What type of API call it was */
	method?: string;
	/** The URL of the API call */
	url: string;
	/** Whether the API call was successful */
	successful?: boolean;
	/** The HTTP status code of the API call */
	status?: number;
	/** The HTTP Status text of the API call */
	statusText?: string;
	/** The content type of the API call response */
	contentType?: string | null;
}

/**
 * Log information from a request to the console when debugging
 * @param entry the data from the request to log
 */
function logRequest(entry: ILogEntry): void {
	if (entry.url.endsWith("/api/Debug/LogDebugData")) {
		//don't keep logging the request to log debug data over and over
		debugNoFlush("Request sent:", entry);
	} else {
		debug("Request sent:", entry);
	}
}
