/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Shared state for alerts
 * @author Tara Feldstein
 * @module Epic.VideoApp.State.Alerts
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import {
	AlertBannerType,
	AlertType,
	IAlert,
	IChoiceAlert,
	IGenericAlert,
	IGenericCountdownAlert,
	Timeout,
	Toast,
	ToastType,
	ToastWithId,
} from "~/types";
import { getPseudoRandomId } from "~/utils/general";
import { IUser } from "~/web-core/interfaces";

/// TYPES ///

export interface IAlertState {
	currentAlerts: IAlert[];
	banner: AlertBannerType | null;
	toasts: Record<string, Toast>;
	dismissedToasts: Record<string, number>;
	disconnectionHandle: Timeout | null;
	backgroundChangedAlert: boolean;
	backgroundName: string;
	backgroundClearedAlert: boolean;
	admittedAlert: boolean;
	displayModeAlert: string;
	waitingRoomAlert: boolean;
	waitingRoomEmptyRoomAlert: boolean;
	languageChangedAlert: boolean;
}

/// INIT ///

export function getInitialState(): IAlertState {
	return {
		currentAlerts: [],
		banner: null,
		toasts: {},
		dismissedToasts: {},
		disconnectionHandle: null,
		backgroundChangedAlert: false,
		backgroundName: "",
		backgroundClearedAlert: false,
		admittedAlert: false,
		displayModeAlert: "",
		waitingRoomAlert: false,
		waitingRoomEmptyRoomAlert: false,
		languageChangedAlert: false,
	};
}

/// REDUCERS ///

// Adds an alert. Currently replaces the old alert, if any
function postGenericAlert(state: IAlertState, message: string): IAlertState {
	const newAlert: IGenericAlert = {
		message: message,
		type: AlertType.genericMessage,
	};

	const newAlerts: IAlert[] = state.currentAlerts?.slice() ?? [];
	newAlerts.push(newAlert);
	return {
		...state,
		currentAlerts: newAlerts,
	};
}

function postGenericCountdownAlert(state: IAlertState, newAlert: IGenericCountdownAlert): IAlertState {
	const newAlerts: IAlert[] = state.currentAlerts?.slice() ?? [];
	newAlerts.push(newAlert);
	return {
		...state,
		currentAlerts: newAlerts,
	};
}

function postChoiceAlert(state: IAlertState, newAlert: IChoiceAlert): IAlertState {
	const newAlerts: IAlert[] = state.currentAlerts.slice();
	newAlerts.push(newAlert);
	return {
		...state,
		currentAlerts: newAlerts,
	};
}

export function postScreenShareWarningAlert(state: IAlertState, shareParticipant: IUser | null): IAlertState {
	const newAlerts: IAlert[] = state.currentAlerts.slice() ?? [];
	newAlerts.push({ type: AlertType.screenShareWarning, shareParticipant });
	return { ...state, currentAlerts: newAlerts };
}

function postEndVisitAlert(state: IAlertState): IAlertState {
	const newAlerts = state.currentAlerts.slice();
	newAlerts.push({ type: AlertType.endVisit });
	return { ...state, currentAlerts: newAlerts };
}

export function postToastAlert(state: IAlertState, toast: Toast): IAlertState {
	// If inbound toast type was dismissed within it's chosen throttle period, don't show again
	const throttleCutoff = state.dismissedToasts[toast.type];

	if (throttleCutoff >= Date.now()) {
		return state;
	}
	// if toast type is specified, it must be unique
	const newToasts = toast.type ? removeToastsOfType(state.toasts, toast.type) : { ...state.toasts };

	const toastId = getPseudoRandomId();
	newToasts[toastId] = toast;

	return { ...state, toasts: newToasts };
}

interface IReplaceParams {
	newToast: Toast;
	replaceType: ToastType;
}

function replaceToast(state: IAlertState, params: IReplaceParams): IAlertState {
	const { newToast: toast, replaceType } = params;

	const newToasts = removeToastsOfTypes(state.toasts, [toast.type, replaceType]);

	const toastId = getPseudoRandomId();
	newToasts[toastId] = toast;

	return { ...state, toasts: newToasts };
}

/**
 * Dismisses a toast notification. If a time is supplied, the toast will be throttled from being
 * displaying until that time is up. If no time is supplied (a value of 0) we will have no throttling.
 *
 * @param state - Current state
 * @param toastId - the toast Id to dismiss
 * @returns - Updated state after removing a toast
 */
function dismissToastAlert(state: IAlertState, toastId: string): IAlertState {
	const newToasts = { ...state.toasts };
	const newDismissedToasts = { ...state.dismissedToasts };
	const toastToDelete = newToasts[toastId];

	/** If we are dismissing a valid toast type, check if we should throttle future toasts */
	if (toastToDelete && toastToDelete.type) {
		const dismissTime = toastToDelete.dismissTimeMS || 0;
		if (dismissTime !== 0) {
			const cutOff = Date.now() + dismissTime;
			newDismissedToasts[toastToDelete.type] = cutOff;
		}
		delete newToasts[toastId];
	}

	return { ...state, toasts: newToasts, dismissedToasts: newDismissedToasts };
}

export function setBanner(state: IAlertState, banner: AlertBannerType | null): IAlertState {
	return { ...state, banner };
}

// Clears the current alert.
export function clearAlert(state: IAlertState): IAlertState {
	// remove the first alert
	const newAlerts: IAlert[] = state.currentAlerts.slice(1);
	return {
		...state,
		currentAlerts: newAlerts,
	};
}

// Clears the first alert of the specified type
export function clearAlertType(state: IAlertState, type: AlertType): IAlertState {
	const newAlerts = removeAlertsOfType(state.currentAlerts, type);
	return { ...state, currentAlerts: newAlerts };
}

/**
 * Clear toasts of a specific type, or clears all toasts if null is passed in
 */
export function clearToasts(state: IAlertState, type: ToastType | null): IAlertState {
	const newToasts = type ? removeToastsOfType(state.toasts, type) : {};
	return { ...state, toasts: newToasts };
}

function clearMultipleToasts(state: IAlertState, types: ToastType[]): IAlertState {
	const updatedToasts = removeToastsOfTypes(state.toasts, types);
	return { ...state, toasts: updatedToasts };
}

// Sets the stored Timeout Handle
// Pass a null timeout to clear
function setDisconnectionTimeout(state: IAlertState, handle: Timeout | null): IAlertState {
	const newState = {
		...state,
		disconnectionHandle: handle,
	};
	return newState;
}

function postScreenShareStartedAlerts(state: IAlertState): IAlertState {
	const newToasts = removeToastsOfTypes(state.toasts, ["remote-screen-shared"]);
	return { ...state, toasts: newToasts, banner: "local-screen-share" };
}

function setBackgroundChangedAlert(state: IAlertState, alert: boolean): IAlertState {
	return { ...state, backgroundChangedAlert: alert };
}

function setBackgroundName(state: IAlertState, background: string): IAlertState {
	return { ...state, backgroundName: background };
}

function setBackgroundClearedAlert(state: IAlertState, alert: boolean): IAlertState {
	return { ...state, backgroundClearedAlert: alert };
}

function setAdmittedAlert(state: IAlertState, alert: boolean): IAlertState {
	return { ...state, admittedAlert: alert };
}

function setDisplayModeAlert(state: IAlertState, displayMode: string): IAlertState {
	return { ...state, displayModeAlert: displayMode };
}

function setWaitingRoomAlert(state: IAlertState, alert: boolean): IAlertState {
	return { ...state, waitingRoomAlert: alert };
}

function setWaitingRoomEmptyRoomAlert(state: IAlertState, alert: boolean): IAlertState {
	return { ...state, waitingRoomEmptyRoomAlert: alert };
}

function setLanguageChangedAlert(state: IAlertState, alert: boolean): IAlertState {
	return { ...state, languageChangedAlert: alert };
}

/// SELECTORS ///

function getCurrentAlert(state: IAlertState): IAlert | null {
	return state.currentAlerts.length ? state.currentAlerts[0] : null;
}

function getBanner(state: IAlertState): AlertBannerType | null {
	return state.banner;
}

function getToastAlerts(state: IAlertState): ToastWithId[] {
	const { toasts } = state;
	return Object.keys(toasts).map((id) => ({ id, ...toasts[id] }));
}

function getDisconnectionHandle(state: IAlertState): Timeout | null {
	return state.disconnectionHandle;
}

/// HELPERS ///

function removeToastsOfTypes(toasts: Record<string, Toast>, types: ToastType[]): Record<string, Toast> {
	let clearedToasts = { ...toasts };
	types.forEach((type) => (clearedToasts = removeToastsOfType(clearedToasts, type)));
	return clearedToasts;
}

function removeAlertsOfType(alerts: IAlert[], type: AlertType): IAlert[] {
	for (let idx = 0; idx < alerts.length; idx++) {
		if (alerts[idx].type === type) {
			alerts.splice(idx);
		}
	}
	return alerts;
}

/**
 * Helper function to remove all toasts of a particular type
 * @param toasts current toasts
 * @param type type of toasts to remove
 * @returns toasts with specific type filtered out
 */
function removeToastsOfType(toasts: Record<string, Toast>, type: ToastType): Record<string, Toast> {
	const newToasts = { ...toasts };

	Object.keys(newToasts).forEach((toastId) => {
		if (newToasts[toastId].type === type) {
			delete newToasts[toastId];
		}
	});

	return newToasts;
}

function getBackgroundChangedAlert(state: IAlertState): boolean {
	return state.backgroundChangedAlert;
}

function getBackgroundName(state: IAlertState): string {
	return state.backgroundName;
}

function getBackgroundClearedAlert(state: IAlertState): boolean {
	return state.backgroundClearedAlert;
}

function getAdmittedAlert(state: IAlertState): boolean {
	return state.admittedAlert;
}

function getDisplayModeAlert(state: IAlertState): string {
	return state.displayModeAlert;
}

function getWaitingRoomAlert(state: IAlertState): boolean {
	return state.waitingRoomAlert;
}

function getWaitingRoomEmptyRoomAlert(state: IAlertState): boolean {
	return state.waitingRoomEmptyRoomAlert;
}

function getLanguageChangedAlert(state: IAlertState): boolean {
	return state.languageChangedAlert;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		postGenericAlert,
		postChoiceAlert,
		postGenericCountdownAlert,
		clearAlert,
		clearAlertType,
		setDisconnectionTimeout,
		postToastAlert,
		dismissToastAlert,
		clearToasts,
		postEndVisitAlert,
		postScreenShareWarningAlert,
		replaceToast,
		clearMultipleToasts,
		setBanner,
		postScreenShareStartedAlerts,
		setBackgroundChangedAlert,
		setBackgroundName,
		setBackgroundClearedAlert,
		setAdmittedAlert,
		setDisplayModeAlert,
		setWaitingRoomAlert,
		setWaitingRoomEmptyRoomAlert,
		setLanguageChangedAlert,
	},
	selectors: {
		getCurrentAlert,
		getBanner,
		getToastAlerts,
		getDisconnectionHandle,
		getBackgroundChangedAlert,
		getBackgroundName,
		getBackgroundClearedAlert,
		getAdmittedAlert,
		getDisplayModeAlert,
		getWaitingRoomAlert,
		getWaitingRoomEmptyRoomAlert,
		getLanguageChangedAlert,
	},
});

store.addSharedState(builtState.sharedState, "alerts");

export const { actionCreators: alertActions, useSharedState: useAlertState, sharedState: state } = builtState;
