/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file shared authentication state
 * @author Colin Walters
 * @module Epic.VideoApp.State.Auth
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { IClientToken } from "~/types";

/// TYPES ///

export interface IAuthState {
	readonly token: IClientToken | null;
	readonly refreshTokenTimer: number | null;
}

/// INIT ///

export function getInitialState(): IAuthState {
	return {
		token: null,
		refreshTokenTimer: null,
	};
}

/// REDUCERS ///
export function clearAuthState(): IAuthState {
	return getInitialState();
}

export function setClientToken(state: IAuthState, newToken: IClientToken | null): IAuthState {
	return { ...state, token: newToken };
}

function setRefreshTokenTimer(state: IAuthState, refreshTokenTimer: number | null): IAuthState {
	return { ...state, refreshTokenTimer: refreshTokenTimer };
}

/// SELECTORS ///

function getClientToken(state: IAuthState): IClientToken | null {
	return state.token;
}

function getRefreshTokenTimer(state: IAuthState): number | null {
	return state.refreshTokenTimer;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: { setClientToken, setRefreshTokenTimer, clearAuthState },
	selectors: { getClientToken, getRefreshTokenTimer },
});

store.addSharedState(builtState.sharedState, "auth");

export const { actionCreators: authActions, useSharedState: useAuthState, sharedState: state } = builtState;
