/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Get vendor token and join the room
 * @author Spencer Eanes
 * @module Epic.VideoApp.Hooks.UseJoinRoom
 */
import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useRef } from "react";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useAuditFeatureUse } from "~/hooks";
import {
	alertActions,
	errorPageActions,
	hardwareTestActions,
	useAlertState,
	useAuthState,
	useHardwareTestState,
	useUserState,
	userActions,
} from "~/state";
import { EventType, IClientToken } from "~/types";
import { IUserPreferencesWithEncryption } from "~/types/user";
import frameMessager from "~/utils/frameMessager";
import { debug } from "~/utils/logging";
import { iOSDetectedAtVersion } from "~/utils/os";
import { makeRequest } from "~/utils/request";
import {
	clearOldGuardOptions,
	loadUserGuardOptions,
	makeGuardOptionsBooleans,
	noGuardrailsOptions,
	saveGuardOptions,
} from "~/utils/userGuardOptions";
import { isEmbeddedMobileView } from "~/utils/windowGlobals";
import { VideoSessionContext } from "~/web-core/components";
import { useStoreError } from "~/web-core/hooks/useStoreError";
import { SessionErrorCodes } from "~/web-core/interfaces";
import { IConnectOptions } from "~/web-core/types/connectOptions";
import { roomActions, useRoomState } from "../state/room";
import { getSkipHardwareTestGuardrails } from "../utils/skipHardwareTestGuardrails";
import { IBasicAuditEvent } from "./auditing";
import { useDisconnect } from "./useDisconnect";

/**
 * Makes a server request with local track data from redux store
 */
export function useJoinRoom(): () => void {
	const dispatch = useDispatch();
	const clientToken = useAuthState((selectors) => selectors.getClientToken(), []);
	const canConnect = useRoomState((selectors) => selectors.getCanConnect(), []);
	const isConnecting = useRoomState((selectors) => selectors.getIsConnecting(), []);
	const localDisplayName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);
	const disconnectionTimeout = useAlertState((selectors) => selectors.getDisconnectionHandle(), []);
	const useWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const useLowBandwidth = useRoomState((selectors) => selectors.getIsLowBandwidthMode(), []);
	const waitingRoomUrl = useRoomState((selectors) => selectors.getWaitingRoomUrl(), []);
	const userKey = useUserState((selectors) => selectors.getUserKey(), []);
	const hideGuardRails = useUserState((selectors) => selectors.getHideGuardrails(), []);
	const guardDismissalTime = useUserState((selectors) => selectors.getGuardDismissalTime(), []);
	const skipHardwareTestGuardrails = getSkipHardwareTestGuardrails(userKey);
	const skipHardwareTest = useHardwareTestState((selectors) => selectors.getSkipHardwareTest(), []);
	const skipHardwareTestQSP = skipHardwareTest && !skipHardwareTestGuardrails;
	const storeError = useStoreError();
	const auditFeatureUse = useAuditFeatureUse();
	const { session } = useContext(VideoSessionContext);

	const { restartAudioContext } = useContext(DeviceContext);

	const userPreferences = useUserState((selectors) => selectors.getPreferences(), []);
	const debuggingLogLevel = useRoomState((selectors) => selectors.getDebuggingLogLevel(), []);

	const disconnect = useDisconnect();

	const getRoomAndConnect = useCallback((): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (!clientToken) {
				reject("Client token is null.");
				return;
			}

			if (!session) {
				reject("No session");
				return;
			}

			session
				.getRoomInfo(clientToken)
				.then(async (roomInfo: IConnectOptions | null) => {
					debug("Retrieved the room information: ", roomInfo);
					//set call info in shared state
					if (!roomInfo?.info.token) {
						reject("No token provided.");
						return;
					}

					roomInfo.logLevel = debuggingLogLevel;
					roomInfo.isLowBandwidth = useLowBandwidth;
					roomInfo.isInWaitingRoom = useWaitingRoom;

					try {
						const didConnect = await session.connect(roomInfo);

						if (!didConnect) {
							reject("Failed to connect to room");
							return;
						}

						dispatch(roomActions.setRoomInfo(roomInfo.info));

						window.session = session;

						frameMessager.postMessage(
							useWaitingRoom ? "Epic.Video.WaitingForAdmission" : "Epic.Video.Connected",
						);

						const decryptedUserPreferences: IUserPreferencesWithEncryption = {
							displayName: localDisplayName,
						};

						// Update IDs on the server and connect users with smart on fire auth
						/* Only send the waitingRoomUrl if the user will see the waiting room when joining (either when 
							admission is required or if no other participants are in the call) */
						const requestModel: IUpdateIDs = {
							roomID: session.roomGuid ?? "",
							participantID: session.localUser.getUserGuid() ?? "",
							decryptedUserPreferences: decryptedUserPreferences,
							waitingRoomUrl:
								useWaitingRoom || session.getRemoteParticipants().length === 0
									? waitingRoomUrl
									: "",
							isEmbeddedMobile: isEmbeddedMobileView(),
						};
						const encryptedUserPreferences = await storeIDS(requestModel, clientToken).catch(
							() => {
								reject("Could not store IDs to the server");
							},
						);
						if (encryptedUserPreferences && userPreferences) {
							userPreferences.encryptedDisplayName =
								encryptedUserPreferences?.displayName ?? "";
							dispatch(userActions.setPreferences(userPreferences));
						}

						//if the user has opted in and skip needs to be set to true
						if (skipHardwareTestGuardrails) {
							dispatch(hardwareTestActions.setDisplaySkipHardwareTestToggleInLobby(false));
							dispatch(hardwareTestActions.setSkipHardwareTest(true));
							const skipEvent: IBasicAuditEvent = {
								feature: EventType.localStorageHardwareTestSkip,
							};
							void auditFeatureUse([skipEvent]);
						}
						//Hiding the option to opt out if a individual is using the QSP to skip
						if (skipHardwareTestQSP) {
							dispatch(hardwareTestActions.setDisplaySkipHardwareTestToggleInLobby(true));
							const skipEvent: IBasicAuditEvent = {
								feature: EventType.automatedHardwareTestSkip,
							};
							void auditFeatureUse([skipEvent]);
						}

						// If we set hideGuardRails to true, then load all options as hidden
						if (hideGuardRails) {
							dispatch(userActions.setGuardOptions(noGuardrailsOptions));
						} else {
							let userGuardOptions = loadUserGuardOptions(userKey);
							// Set expired properties to null and save cleaned object to localStorage
							userGuardOptions = clearOldGuardOptions(userGuardOptions, guardDismissalTime);
							saveGuardOptions(userKey, userGuardOptions);
							dispatch(userActions.setGuardOptions(makeGuardOptionsBooleans(userGuardOptions)));
						}

						resolve();
					} catch (errorBase) {
						const vendorError = session.processError(errorBase);
						// Display an error to the user that there are too many users in the call
						// Don't mark it as a join room error when the room is full
						if (vendorError) {
							vendorError.message = `Failed Join - ${vendorError.message}`;
							vendorError.updateConnectionStatus = true;
							if (
								vendorError.sessionErrorCode ===
								SessionErrorCodes.roomMaxParticipantsExceededError
							) {
								dispatch(
									errorPageActions.setErrorCard({
										title: ErrorTokenNames.joinRoomFullHeader,
										message: ErrorTokenNames.joinRoomFullBody,
									}),
								);
								vendorError.isJoinRoomError = false;
							} else {
								vendorError.isJoinRoomError = true;
							}
							storeError(vendorError);
						}
						reject("There was a problem connecting to the room.");
					}
				})
				.catch((error: Error) => {
					// Redirect to error page if we can't reach the server or an error occurs
					// Only store vendor errors to the servers, general errors don't provide relevant data.
					const vendorError = session.processError(error);
					if (vendorError) {
						vendorError.message = `Failed Join - ${vendorError.message}`;
						vendorError.updateConnectionStatus = true;
						vendorError.isJoinRoomError = true;
						storeError(vendorError);
					}
					reject("Server error, redirecting to error page.");
				});
		});
	}, [
		clientToken,
		session,
		debuggingLogLevel,
		useLowBandwidth,
		useWaitingRoom,
		dispatch,
		localDisplayName,
		waitingRoomUrl,
		userPreferences,
		skipHardwareTestGuardrails,
		skipHardwareTestQSP,
		hideGuardRails,
		auditFeatureUse,
		userKey,
		guardDismissalTime,
		storeError,
	]);

	// Connects to the room
	const connectToRoom = useCallback(() => {
		getRoomAndConnect()
			.then(() => {
				// reset the isConnecting state once we successfully join the room
				dispatch(roomActions.setIsConnecting(false));
			})
			.catch((error: Error) => {
				debug(error);
				disconnect(true);
			})
			/** often it seems the initial join volume on iOS15 is hitting a limiter causing pumping,
				 (background cycle fixes this) so restart the AudioContext right after joining 		*/
			.finally(() => {
				if (iOSDetectedAtVersion("15+")) {
					void restartAudioContext();
				}
			});
	}, [getRoomAndConnect, dispatch, disconnect, restartAudioContext]);

	const connectRef = useRef(connectToRoom);
	useEffect(() => {
		connectRef.current = connectToRoom;
	}, [connectToRoom]);

	// Once there has been an attempt to join and a JWT update, actually proceed with joining the visit
	useEffect(() => {
		if (canConnect && isConnecting) {
			connectRef.current();
		}
	}, [canConnect, isConnecting]);

	// Actually attempts to join the room
	const joinRoom = useCallback(() => {
		if (isConnecting) {
			return;
		}
		dispatch(roomActions.setIsConnecting(true));

		// If the user has dismissed their timeout alert, clear it here now that they are joining on time
		if (disconnectionTimeout) {
			clearTimeout(disconnectionTimeout);
			dispatch(alertActions.setDisconnectionTimeout(null));
		}
	}, [dispatch, isConnecting, disconnectionTimeout]);

	return joinRoom;
}

interface IUpdateIDs {
	roomID: string;
	participantID: string;
	decryptedUserPreferences: IUserPreferencesWithEncryption;
	waitingRoomUrl: string;
	isEmbeddedMobile?: boolean;
}

/**
 * Stores the IDs to Cosmos
 */
async function storeIDS(
	data: IUpdateIDs,
	clientToken: IClientToken,
): Promise<IUserPreferencesWithEncryption | null> {
	return makeRequest<IUserPreferencesWithEncryption>(
		"/api/VideoCall/UpdateSIDs",
		"POST",
		clientToken,
		data,
	);
}
