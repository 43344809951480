/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file change camera in call control button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.ChangeCameraButton
 */

import React, { FC } from "react";
import { useCameraDevices, useStrings } from "~/hooks";
import ChangeCamera from "~/icons/changeCamera";
import { isIOS, isMobile } from "~/utils/os";
import { useChangeCamera } from "../hooks/useChangeCamera";
import ControlButton from "./ControlButton";

/**
 * Props for ChangeCameraButton component
 */
interface IProps {
	/** Whether or not the button should be rendered without a label */
	noLabel?: boolean;

	/** Whether or not the button should be rendered without a tooltip */
	noTooltip?: boolean;

	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}

enum TokenNames {
	label = "Label",
}

/**
 * The ChangeCameraButton component
 * @param props The props ;)
 */
const ChangeCameraButton: FC<IProps> = (props) => {
	const { noLabel, noTooltip, tabString } = props;
	const cameraCount = useCameraDevices().length;
	const changeCamera = useChangeCamera();

	const strings = useStrings("ChangeCameraButton", Object.values(TokenNames));

	if ((!isMobile() || cameraCount !== 2) && !isIOS()) {
		return null;
	}

	return (
		<ControlButton
			icon={ChangeCamera}
			ariaLabel={
				tabString === undefined
					? strings[TokenNames.label]
					: strings[TokenNames.label] + String(tabString)
			}
			onClick={changeCamera}
			keyboardShortcut={[]}
			buttonText={!noLabel ? strings["Label"] : undefined}
			tooltipText={!noTooltip ? strings["Label"] : undefined}
		/>
	);
};

ChangeCameraButton.displayName = "ChangeCameraButton";

export default ChangeCameraButton;
