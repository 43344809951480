/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Button to access the admit options menu
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Buttons.AdmitOptionsButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import LeaveCall from "~/icons/leave";
import { uiActions, useUserState } from "~/state";
import { determineMenuActionType, useUIState } from "~/state/ui";
import ControlButton from "./ControlButton";

// button's ID in HTML used to focus it from BaseMenu
export const admitOptionsButtonId = "admit-options-button";

enum TokenNames {
	label = "Label",
}

interface IProps {
	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}

/**
 * AdmitOptionsButton
 */
const AdmitOptionsButton: FC<IProps> = (props) => {
	const { tabString } = props;
	const dispatch = useDispatch();
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const strings = useStrings("AdmitOptionsButton", Object.values(TokenNames));
	const canAdmit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);

	const onClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			// Don't switch menus if the current menu is background effects; this is to prevent opening the admit options menu from shared shortcut alt+a
			if (visibleMenu === "background") {
				return;
			}

			const actionType = determineMenuActionType(event);
			dispatch(uiActions.toggleVisibleMenu({ menu: "admitOptions", actionType }));
		},
		[dispatch, visibleMenu],
	);

	/* Only allow admit options to be set by users that can admit and can moderate. Prevent situations where users are stuck in a moderated state */
	if (!canModerate || !canAdmit) {
		return null;
	}

	const pressed = visibleMenu === "admitOptions";

	return (
		<ControlButton
			icon={LeaveCall}
			buttonText={strings[TokenNames.label]}
			ariaLabel={strings[TokenNames.label] + String(tabString)}
			onClick={onClick}
			keyboardShortcut={["alt", "a"]}
			supplementaryIcon="continue"
			ariaExpanded={pressed}
			id={admitOptionsButtonId}
		/>
	);
};

AdmitOptionsButton.displayName = "AdmitOptionsButton";

export default AdmitOptionsButton;
