/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Options used for connecting to a video call
 * @author Chance Overberg
 * @module Epic.VideoApp.WebCore.Types.ConnectOptions
 */

import { DebuggingLogLevel } from "~/state/room";
import { IClientToken, TwilioRegion } from "~/types";

export interface IConnectOptions {
	info: IConnectResponseDTO;
	logLevel: DebuggingLogLevel;
	isLowBandwidth: boolean;
	isInWaitingRoom: boolean;
	clientToken?: IClientToken;
}

// DTO for connection data for video vendors
export interface IConnectResponseDTO {
	token: string;
	roomIdentifier: string;
	roomID: string | null;
	userID: string;
	patientName: string | null;
	conferenceDateIso: string | null;
	regionID?: TwilioRegion;
	apiKey?: string | null;
}
