/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file base page of the drop down more menu
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Menu.BaseMenu
 */

import React, { FC, ReactElement, useEffect, useMemo } from "react";
import { useCameraDevices, useStrings, useWindowSize } from "~/hooks";
import { useBackgroundProcessorsState, useUIState, useUserState } from "~/state";
import { I18n } from "~/utils/i18n";
import { isIOS, isMobile } from "~/utils/os";
import { isPictureInPictureSupported } from "~/utils/pictureInPicture";
import { stringFormat } from "~/utils/strings";
import { isEmbeddedMobileView } from "~/utils/windowGlobals";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import AboutEvcButton from "../Buttons/AboutEvcButton";
import AdmitOptionsButton from "../Buttons/AdmitOptionsButton";
import ChangeBackgroundButton from "../Buttons/ChangeBackgroundButton";
import ChangeCameraButton from "../Buttons/ChangeCameraButton";
import DisplayModeButton from "../Buttons/DisplayModeButton";
import FullscreenButton from "../Buttons/FullscreenButton";
import LanguageSelectorButton from "../Buttons/LanguageSelectorButton";
import ManageDevicesButton from "../Buttons/ManageDevicesButton";
import ParticipantsButton from "../Buttons/ParticipantsButton";
import PictureInPictureButton from "../Buttons/PictureInPictureButton";
import ScreenShareButton from "../Buttons/ScreenShareButton";
import styles from "../ControlsHeader.module.scss";
import { useHeaderDisplayMode } from "../hooks/useHeaderDisplayMode";
import { baseMenuButtonIds } from "../hooks/useMenuPages";
import { useShowParticipantButtonInHeader } from "../hooks/useShowParticipantButtonInHeader";

// when using style modules CSS class names are modified to remain unique between modules
// export the actual selector that can be used to find the BaseMenu in the DOM
const baseClassName = "moreOptionsMenu";
export const baseMenuSelector = `.${styles[baseClassName]}`;

export const MinWidthForChangeCamInDrawer = 350;
export const MinWidthForChangeCamInHeader = 1000;
export const MinWidthForPiPButtonInHeader = 1000;

/**
 * Props for the BaseMenu component
 */
interface IProps {
	/** Whether or not the base menu is the active menu */
	active: boolean;
}

enum TokenNames {
	optionLabel = "OptionLabel",
}

interface IBaseMenuButton {
	component: JSX.Element;
	condition: boolean;
}

/**
 * The BaseMenu component ("More options" menu)
 */
const BaseMenu: FC<IProps> = (props) => {
	const { active } = props;
	const [, prevVisibleMenu] = useUIState((selectors) => selectors.getVisibleMenuHistory(), []);
	const menuActionType = useUIState((selectors) => selectors.getMenuActionType(), []);
	const strings = useStrings("BaseMenu", Object.values(TokenNames));

	// When this page becomes active, if we're backing up from a child menu, focus that menu's button
	useEffect(() => {
		if (!active) {
			return;
		}

		const defaultMenuButton = "devices";
		const id: string | null = baseMenuButtonIds[prevVisibleMenu ?? defaultMenuButton];
		if (!id) {
			return;
		}

		const element = document.getElementById(id);

		// Focus the element after the menu has been opened and the DOM has updated
		setTimeout(() => {
			element?.focus({ preventScroll: true });
		}, 0);
	}, [active, prevVisibleMenu, menuActionType]);

	// Menu button conditions
	const isProcessorsLoaded =
		useBackgroundProcessorsState((selectors) => selectors.getProcessorLoadStatus(), []) === "finished";
	const { width } = useWindowSize();
	const mobile = useMemo(() => isMobile(), []);
	const embeddedMobileView = useMemo(() => isEmbeddedMobileView(), []);
	const canAdmit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	const headerDisplayMode = useHeaderDisplayMode();
	const cameraCount = useCameraDevices().length;
	const participantsInHeader = useShowParticipantButtonInHeader();
	const screenShareSupport = useIsScreenShareSupported();
	const canChangeLocales = useMemo(() => I18n.canChangeLocales(), []);
	const [canShareScreen] = useUserState(
		(selectors) => [selectors.getUserPermission("canShareScreen"), selectors.getScreenShareLock()],
		[],
	);

	// Some menu buttons are used in several locations, so they have internal logic to determine if they should be shown
	// and will otherwise return null. We can't determine if a component is null from its parent, so the logic is repeated here
	// to allow for each button to know it's place in the menu order.
	const changeBackgroundButtonIsNull = !isProcessorsLoaded;
	const changeCameraButtonIsNull = (!isMobile() || cameraCount !== 2) && !isIOS();
	const screenShareButtonIsNull = !screenShareSupport || !canShareScreen;
	const fullScreenButtonIsNull = !document.fullscreenEnabled || mobile || embeddedMobileView;
	const admitOptionsButtonIsNull = !canModerate || !canAdmit;
	const pipButtonIsNull = !isPictureInPictureSupported();
	const languageButtonIsNull = !canChangeLocales;

	// Determine if buttons should be included in this menu
	const showChangeBackgroundButton = !changeBackgroundButtonIsNull;
	const showChangeCameraButton =
		!changeCameraButtonIsNull &&
		(headerDisplayMode === "controls-in-header"
			? width < MinWidthForChangeCamInHeader
			: width < MinWidthForChangeCamInDrawer);
	const showScreenShareButton = !screenShareButtonIsNull && headerDisplayMode !== "controls-in-header";
	const showFullscreenButton = !fullScreenButtonIsNull;
	const showAdmitOptionsButton = !admitOptionsButtonIsNull;
	const showParticipantButtonInMenu = !participantsInHeader;
	const showPiPButtonInMenu = !pipButtonIsNull && width < MinWidthForPiPButtonInHeader;
	const showLanguageButton = !languageButtonIsNull;

	const buttons: IBaseMenuButton[] = [
		{ component: <ManageDevicesButton />, condition: true },
		{ component: <ChangeBackgroundButton />, condition: showChangeBackgroundButton },
		{ component: <ChangeCameraButton noTooltip />, condition: showChangeCameraButton },
		{ component: <ScreenShareButton noTooltip />, condition: showScreenShareButton },
		{ component: <FullscreenButton />, condition: showFullscreenButton },
		{ component: <AdmitOptionsButton />, condition: showAdmitOptionsButton },
		{ component: <ParticipantsButton noTooltip />, condition: showParticipantButtonInMenu },
		{ component: <PictureInPictureButton inHeader />, condition: showPiPButtonInMenu },
		{ component: <DisplayModeButton />, condition: true },
		{ component: <LanguageSelectorButton context="menu" />, condition: showLanguageButton },
		{ component: <AboutEvcButton />, condition: true },
	];
	const visibleButtons = buttons.filter((b) => b.condition);
	const renderedButtons = addTabStrings(visibleButtons, strings[TokenNames.optionLabel]);

	return (
		<ul className={styles[baseClassName]}>
			{renderedButtons.map((component, index) => (
				<li key={index}>{component}</li>
			))}
		</ul>
	);
};

/**
 * Adds tab strings to the buttons
 * @param buttons the buttons to add tab strings to
 * @param optionLabel the string to format with the tab number
 * @returns the button components with tab strings
 */
const addTabStrings = (buttons: IBaseMenuButton[], optionLabel: string): ReactElement[] => {
	return buttons.map((button, index) =>
		React.cloneElement(button.component, {
			tabString: stringFormat(optionLabel, index + 1, buttons.length),
		}),
	);
};

BaseMenu.displayName = "BaseMenu";

export default React.memo(BaseMenu);
