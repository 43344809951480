/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Manages the connection to the chat server and provides the connection status to the rest of the application
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.ConnectionManagement
 */
import { useDispatch } from "@epic/react-redux-booster";
import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { ChatConnectionStatus, ISocketActions, IUpdateDisplayNameMessage } from "~/types/websockets";
import { VideoSessionContext } from "~/web-core/components";
import { messageActions, useAuthState, useMessageState, useRoomState } from "../../state";
import { IChatNegotiateResponse, IClientToken } from "../../types";
import { secondsToMs } from "../../utils/dateTime";
import { makeRequest } from "../../utils/request";
import { useParticipantName } from "../Participants/hooks/useParticipantName";
import { WebSocketContext } from "../WebSocket/WebSocketConnection";
import { canGetToken } from "./hooks/useCanGetToken";
import { useChatSocketMessageHandler } from "./hooks/useChatSocketMessageHandler";

interface IProps {
	children: React.ReactChild;
	shouldConnectToChat: boolean;
}

export interface ISocketConnectionContext {
	chatConnectionStatus: ChatConnectionStatus;
	participantsTypingTimeoutsRef: React.MutableRefObject<{
		[participantId: string]: NodeJS.Timeout | number;
	}>;
}

// The context for managing the chat connection.
export const ConnectionContext = React.createContext<ISocketConnectionContext>({
	chatConnectionStatus: "NotStarted",
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	participantsTypingTimeoutsRef: { current: {} } as React.MutableRefObject<{
		[participantId: string]: NodeJS.Timeout | number;
	}>,
});

// This module exports a React component that provides a chat connection context.
const ConnectionManagementContext: FC<PropsWithChildren<IProps>> = (props) => {
	const { children, shouldConnectToChat } = props;
	const { openSocket, sendSocketMessage, isWebsocketOpen, closeSocket } = useContext(WebSocketContext);
	const clientToken = useAuthState((selectors) => selectors.getClientToken(), []);
	const localDisplayName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);
	const { session } = useContext(VideoSessionContext);
	const NUMBER_FAILED_MSGS_BEFORE_RECONNECT = 2;
	const localUserId = session?.localUser?.getUserIdentity() ?? "";
	const callCounter = useRef(0);
	const participantsTypingTimeoutsRef: React.MutableRefObject<{
		[participantId: string]: NodeJS.Timeout | number;
	}> = useRef({});
	const messageCallback = useChatSocketMessageHandler(localUserId, participantsTypingTimeoutsRef);
	const [chatConnectionStatus, setChatConnectionStatus] = useState<ChatConnectionStatus>("NotStarted");
	const callTimes = useRef<number>(0);
	const localUser = session?.localUser;
	const defaultCallerName = useParticipantName(localUser || null, true);
	const [hasNameChanged, setHasNameChanged] = useState(false);
	const nameSentRef = useRef("");
	const isUsingLocalNameRef = useRef(false);
	const tokenRef = useRef(clientToken);
	const nameRef = useRef(localDisplayName);
	const defaultNameRef = useRef(defaultCallerName);
	const chatConnectionStatusRef = useRef(chatConnectionStatus);
	const [shouldGiveUpRetrying, setShouldGiveUpRetrying] = useState(false);
	const dispatch = useDispatch();
	const numberOfFailedMsgsInARow = useMessageState(
		(selectors) => selectors.getNumberOfFailedMessagesInARow(),
		[],
	);

	// Use effects to update refs when the values change
	useEffect(() => {
		tokenRef.current = clientToken;
	}, [clientToken]);

	useEffect(() => {
		nameRef.current = localDisplayName;
	}, [localDisplayName]);

	useEffect(() => {
		defaultNameRef.current = defaultCallerName;
		setHasNameChanged(true);
	}, [defaultCallerName]);

	useEffect(() => {
		chatConnectionStatusRef.current = chatConnectionStatus;
	}, [chatConnectionStatus]);

	// Manages the display name of the user, using a default name if no name is provided.
	const handleDisplayNameChange = useCallback((displayName: string) => {
		let newDisplayName = displayName;
		if (newDisplayName === "" || nameRef.current === "") {
			isUsingLocalNameRef.current = false;
			newDisplayName = defaultNameRef.current;
		} else {
			isUsingLocalNameRef.current = true;
		}
		setHasNameChanged(false);
		nameSentRef.current = newDisplayName;
		return newDisplayName;
	}, []);

	const sendNameChangeSocketEvent = useCallback(
		(
			// eslint-disable-next-line @typescript-eslint/naming-convention
			updateDisplayNameData: { NewDisplayName: string },
		) => {
			const updateDisplayNameMessage: IUpdateDisplayNameMessage = {
				data: updateDisplayNameData,
				messageId: crypto.randomUUID(),
				messageType: "UpdateDisplayName",
				version: "1.0",
				requiresAck: false,
			};
			sendSocketMessage(updateDisplayNameMessage);
		},
		[sendSocketMessage],
	);

	useEffect(() => {
		if (
			chatConnectionStatus === "Connected" &&
			hasNameChanged &&
			!isUsingLocalNameRef.current &&
			nameSentRef.current !== defaultNameRef.current
		) {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			const updateDisplayNameData = { NewDisplayName: defaultNameRef.current };
			sendNameChangeSocketEvent(updateDisplayNameData);
			setHasNameChanged(false);
		}
	}, [chatConnectionStatus, sendNameChangeSocketEvent, hasNameChanged]);

	/* Callback function that attempts to negotiate a chat connection with the hook server.
	 * If the maximum number of attempts is reached, it waits for the duration of the time window before trying again.
	 */
	const getToken = useCallback(
		async (clientToken: IClientToken, displayName: string): Promise<string | undefined> => {
			if ((await canGetToken(callTimes, callCounter)) === false) {
				setShouldGiveUpRetrying(true);
				return;
			}

			const newDisplayName = handleDisplayNameChange(displayName);

			try {
				const response = await makeRequest<IChatNegotiateResponse>(
					"/api/Chat/Negotiate",
					"POST",
					clientToken,
					{
						displayName: newDisplayName,
					},
				);
				return response.serviceAddress;
			} catch (exception) {
				console.warn((exception as Error).message);
			}

			return undefined;
		},
		[handleDisplayNameChange],
	);

	// Create a socket options object to pass to the WebSocket hook
	// This object contains the callback functions for the WebSocket events
	const socketOptions: ISocketActions = useMemo(() => {
		const onOpen = (_event: WebSocketEventMap["open"]): void => {
			setChatConnectionStatus("Connecting");
		};
		const onClose = (_event: WebSocketEventMap["close"]): void => {
			setChatConnectionStatus("Disconnected");
		};
		const onError = (_event: WebSocketEventMap["error"]): void => {
			setChatConnectionStatus("Disconnected");
		};
		const onMessage = (event: WebSocketEventMap["message"]): void => {
			messageCallback(event);
		};

		return {
			socketOnMessage: onMessage,
			socketOnError: onError,
			socketOnClose: onClose,
			socketOnOpen: onOpen,
		};
	}, [messageCallback]);

	// Function to start the WebSocket connection
	const startSocket = useCallback(
		(serviceAddress: string) => {
			const success = openSocket(serviceAddress, socketOptions);
			if (!success) {
				setChatConnectionStatus("Disconnected");
			}
		},
		[openSocket, socketOptions],
	);

	// Function to start the connection to the chat server
	const startConnection = useCallback(
		async (token: IClientToken, displayName: string) => {
			const socketURL = await getToken(token, displayName);
			if (!socketURL) {
				setChatConnectionStatus("Disconnected");
				return;
			}

			void startSocket(socketURL);
		},
		[getToken, startSocket],
	);

	// Function to open the connection to the chat server
	const openConnection = useCallback(async () => {
		setChatConnectionStatus((previousState) => {
			if (previousState === "NotStarted") {
				return "Connecting";
			}

			return "Reconnecting";
		});

		if (!tokenRef.current) {
			console.warn("Token is not set");
			return;
		}
		await startConnection(tokenRef.current, nameRef.current);
	}, [startConnection]);

	// If the connection status is "Disconnected", open the connection
	useEffect(() => {
		if (shouldConnectToChat && !shouldGiveUpRetrying) {
			if (chatConnectionStatus === "Disconnected" || chatConnectionStatus === "NotStarted") {
				void openConnection();
			}
		}
	}, [chatConnectionStatus, openConnection, shouldConnectToChat, shouldGiveUpRetrying]);

	// If the connection status is "Connecting", set a timeout to check if the connection is still open
	// If the status is still connecting and the connection is open, set the status to "Connected"
	// If the connection is closed or the status has changed, set the status to "Disconnected"
	useEffect(() => {
		if (chatConnectionStatus === "Connecting") {
			const timeout = setTimeout(() => {
				if (chatConnectionStatusRef.current === "Connecting" && isWebsocketOpen()) {
					setChatConnectionStatus("Connected");
					callCounter.current = 0;
				} else {
					setChatConnectionStatus("Disconnected");
				}
			}, secondsToMs(1.5));

			return () => {
				clearTimeout(timeout);
			};
		}

		chatConnectionStatusRef.current = chatConnectionStatus;
	}, [chatConnectionStatus, isWebsocketOpen, sendNameChangeSocketEvent]);

	useEffect(() => {
		if (numberOfFailedMsgsInARow > NUMBER_FAILED_MSGS_BEFORE_RECONNECT) {
			setShouldGiveUpRetrying(false);
			closeSocket();
			setChatConnectionStatus("Disconnected");
			dispatch(messageActions.resetNumberOfFailedMessagesInARow());
		}
	}, [closeSocket, dispatch, numberOfFailedMsgsInARow]);

	return (
		<ConnectionContext.Provider value={{ chatConnectionStatus, participantsTypingTimeoutsRef }}>
			{children}
		</ConnectionContext.Provider>
	);
};

export default ConnectionManagementContext;
