/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file combined state for disconnection
 * @author Colin Walters
 * @module Epic.VideoApp.State.Combined.Disconnect
 */

import { withSharedStates } from "@epic/react-redux-booster";
import * as alerts from "../alerts";
import * as auth from "../auth";
import * as backgroundProcessors from "../backgroundProcessors";
import * as branding from "../branding";
import * as errorPage from "../errorPage";
import * as feedbackSurvey from "../feedbackSurvey";
import * as hardwareTest from "../hardwareTest";
import * as imageCapture from "../imageCapture";
import * as localTracks from "../localTracks";
import * as room from "../room";
import * as settings from "../settings";
import * as speakers from "../speakers";
import * as ui from "../ui";
import * as user from "../user";

/// COMBINED STATE TYPES ///

type CombinedState = [
	alerts.IAlertState,
	auth.IAuthState,
	backgroundProcessors.IBackgroundProcessorsState,
	branding.IBrandingState,
	errorPage.IErrorPageState,
	hardwareTest.IHardwareTestState,
	imageCapture.IImageCaptureState,
	localTracks.ILocalTrackState,
	room.IRoomState,
	settings.ISettingsState,
	speakers.ISpeakerState,
	ui.IUIState,
	user.IUserState,
	feedbackSurvey.IFeedbackSurveyState,
];

/// COMBINED REDUCERS ///

/**
 * Reset shared state back to its initial values, except values needed for error/disconnected screens
 * @param state shared state prior to this action
 * @returns the new shared state after this action is applied
 */
function clearStateForDisconnect(state: CombinedState): CombinedState {
	const [
		,
		authState,
		,
		brandingState,
		errorPageState,
		,
		,
		,
		roomState,
		settingsState,
		,
		,
		userState,
		feedbackSurveyState,
	] = state;
	return [
		alerts.getInitialState(),
		auth.getInitialState(),
		backgroundProcessors.getInitialState(),
		brandingState,
		errorPageState,
		hardwareTest.getInitialState(true),
		imageCapture.getInitialState(),
		localTracks.getInitialState(),
		room.getInitialState(true, roomState),
		settingsState,
		speakers.getInitialState(),
		ui.getInitialState(),
		user.setDisconnectedState(userState),
		// Move the current JWT into the feedback survey state, to use as a back up if failures/timeouts happen while retrieving a survey-specific JWT
		feedbackSurvey.setClientToken(feedbackSurveyState, authState.token),
	];
}

/// BUILD IT ///

export const disconnectCombinedReducers = {
	clearStateForDisconnect: withSharedStates(
		alerts.state,
		auth.state,
		backgroundProcessors.state,
		branding.state,
		errorPage.state,
		hardwareTest.state,
		imageCapture.state,
		localTracks.state,
		room.state,
		settings.state,
		speakers.state,
		ui.state,
		user.state,
		feedbackSurvey.state,
	).buildReducer(clearStateForDisconnect),
};
