/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file hook to gather stats from the peerConnection object a la webrtc-internals
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Hooks.UsePeerConnectionStats
 */

import { useCallback, useEffect, useRef, useState } from "react";
import { useInterval } from "~/hooks";
import { useAuthState, useRoomState } from "~/state";
import { DebuggingLogLevel } from "~/state/room";
import { IGetStatsDto, ISignalingRoom, IStatsCollector, getEmptyStats, getEmptyTrackArray } from "~/types";
import { secondsToMs } from "~/utils/dateTime";
import { debug, saveWebRtcLogs } from "~/utils/logging";
import { ISession } from "~/web-core/interfaces";
import { TwilioSession } from "~/web-core/vendor/twilio/implementations";

const GET_STATS_INTERVAL_SECONDS = 1;
const SECONDS_PER_SERVER_LOG = 60;
const COUNTER_INCREMENTS_PER_LOG = SECONDS_PER_SERVER_LOG / GET_STATS_INTERVAL_SECONDS;

export function usePeerConnectionStats(session: ISession): void {
	const clientToken = useAuthState((selectors) => selectors.getClientToken(), []);
	const debuggingLogLevel = useRoomState((selectors) => selectors.getDebuggingLogLevel(), []);

	const counterRef = useRef(0);
	const statsRef = useRef<StandardizedStatsResponse[]>([]);

	const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null);

	// convert statsRef to IStatsCollector, push to logging file and reset statsRef
	const pushToLog = useCallback((): void => {
		if (!clientToken) {
			return;
		}

		const statsDto: IGetStatsDto = {
			roomId: session.roomGuid || "",
			participantIdentity: session.getLocalParticipant()?.getUserIdentity() || "",
			timestamp: Date.now(),
			data: getRTCTimeSeriesData(statsRef.current),
		};

		void saveWebRtcLogs(clientToken, JSON.stringify(statsDto));
		statsRef.current = [];
	}, [clientToken, session]);

	// keep peer connection up to date with the twilio rooms peer connection
	useEffect(() => {
		if (!session) {
			return;
		}

		// Only support client logging of this data for Twilio
		// Other video vendors tend to support this via REST API after the call
		if (!(session instanceof TwilioSession) || !session.room) {
			return;
		}

		const room = session.room;
		const updatePeerConnection = (): void => {
			const rm = room as ISignalingRoom;
			setPeerConnection([...rm._signaling._peerConnectionManager._peerConnections.values()][0]);
		};

		if (!peerConnection) {
			updatePeerConnection();
			return;
		}
		room.on("reconnected", updatePeerConnection);
		return () => {
			room.off("reconnected", updatePeerConnection);
		};
	}, [peerConnection, session]);

	// get webRTC internals stats from peer connection and add them to statsArray
	const appendLatestStats = useCallback(async (): Promise<void> => {
		let stats: StandardizedStatsResponse;
		try {
			// cast peer connection to our definition of IRTCPeerConnection for clean access to getStats()
			stats = await (peerConnection as unknown as IRTCPeerConnection).getStats();
		} catch (e: any) {
			debug("Failure to peerConnection getStats()");
			return;
		}

		statsRef.current.push(stats);
		counterRef.current += 1;

		if (counterRef.current % COUNTER_INCREMENTS_PER_LOG === 0 && counterRef.current > 0) {
			pushToLog();
		}
	}, [peerConnection, pushToLog]);

	const collectInfoAndLog = useCallback(() => {
		void appendLatestStats();
	}, [appendLatestStats]);

	// setup an interval to collect info and log
	useInterval(
		collectInfoAndLog,
		secondsToMs(GET_STATS_INTERVAL_SECONDS),
		pushToLog,
		!peerConnection || !session || debuggingLogLevel !== DebuggingLogLevel.verbose,
	);
}

/**
 * Unwrap an array of objects from peerConnection.getStats() into an object of arrays the data as a time series
 * @param data An array of standardized stats responses
 * @returns Time series IStatsCollector data in the format we expect to log on the server
 */
function getRTCTimeSeriesData(data: StandardizedStatsResponse[]): IStatsCollector {
	const timeSeriesStats = getEmptyStats();

	// foreach row of stats responses
	data.forEach((singleStatsObj): void => {
		populateTimeSeries(timeSeriesStats, singleStatsObj);
	});

	return timeSeriesStats;
}

/* eslint-disable guard-for-in */

/**
 * Moves webRTC stats from a single instants stats object into a time series object of arrays
 * @param timeSeriesStats Time series stats to add single instant stats to
 * @param singleInstantStats getStats() response from a single instant
 */
function populateTimeSeries(
	timeSeriesStats: IStatsCollector,
	singleInstantStats: StandardizedStatsResponse,
): void {
	try {
		// populate activeIceCandidatePair
		for (const key in timeSeriesStats.activeIceCandidatePair) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			timeSeriesStats.activeIceCandidatePair[key].push(
				getNullishProperty(singleInstantStats.activeIceCandidatePair, key),
			);
		}

		// populate (local/remote)(Audio/Video)TrackStats
		arrayOfTrackStatsToMap(timeSeriesStats.localAudioTrackStats, singleInstantStats.localAudioTrackStats);
		arrayOfTrackStatsToMap(
			timeSeriesStats.remoteAudioTrackStats,
			singleInstantStats.remoteAudioTrackStats,
		);
		arrayOfTrackStatsToMap(timeSeriesStats.localVideoTrackStats, singleInstantStats.localVideoTrackStats);
		arrayOfTrackStatsToMap(
			timeSeriesStats.remoteVideoTrackStats,
			singleInstantStats.remoteVideoTrackStats,
		);
	} catch (e) {
		debug("Error populating time series data: ", e);
	}
}

/**
 * Moves array of StandardizedTrackStatsReport into a map based on the id of the track
 * @param mapOfTrackStats record of track stats mapped by track id to add array of instant stats to
 * @param arrayOfTrackStats Array of track stats that needs to be organized into a map
 */
function arrayOfTrackStatsToMap(
	mapOfTrackStats: Record<string, StandardizedTrackStatsArray>,
	arrayOfTrackStats: StandardizedTrackStatsReport[],
): void {
	for (const singleTrackStats of arrayOfTrackStats) {
		const id = singleTrackStats.ssrc;
		let timeSeriesTrackMap = mapOfTrackStats[id];
		if (!timeSeriesTrackMap) {
			timeSeriesTrackMap = getEmptyTrackArray();
			mapOfTrackStats[id] = timeSeriesTrackMap;
		}
		for (const key in timeSeriesTrackMap) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			timeSeriesTrackMap[key].push(getNullishProperty(singleTrackStats, key));
		}
	}
}

/**
 * Since we're using objects that are not strongly typed,
 * make sure that the field we're extracting from the object actually exists otherwise return null
 * @param object Object with string keys (i.e. StandardizedTrackStatsReport)
 * @param key string key that is an expected property of object
 * @return Returns the property of an object if it exists, otherwise null
 */
function getNullishProperty(object: StringKeyObject, key: string): string | number | null {
	if (object) {
		return (object[key] as string | number) || null;
	}
	return null;
}
