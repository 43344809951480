/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file participants in call controls button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.ParticipantsButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import Participants from "~/icons/participants";
import { uiActions, useUIState } from "~/state";
import { determineMenuActionType } from "~/state/ui";
import { resolveClassName } from "~/utils/className";
import styles from "../ControlsHeader.module.scss";
import { useShowParticipantNotification } from "../hooks/useShowParticipantNotification";
import ControlButton from "./ControlButton";

// button's ID in HTML used to focus it from BaseMenu
export const participantsButtonId = "participants-button";

interface IProps {
	/** Whether or not the button is rendered in the header */
	inHeader?: boolean;

	/** Whether or not the button should be rendered without a tooltip */
	noTooltip?: boolean;

	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}

/** String tokens used by ParticipantsButton component */
enum TokenNames {
	label = "Label",
	ariaLabel = "AriaLabel",
	tooltip = "TooltipNoHotkey",
	notificationText = "NotificationText",
}

/**
 * The ParticipantsButton component
 */
const ParticipantsButton: FC<IProps> = (props: IProps) => {
	const { inHeader, noTooltip, tabString } = props;
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const dispatch = useDispatch();
	const showNotification = useShowParticipantNotification();
	const strings = useStrings("ParticipantsButton", Object.values(TokenNames));

	const onClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			const actionType = determineMenuActionType(event);
			dispatch(
				uiActions.toggleVisibleMenu({
					menu: "participants",
					actionType,
				}),
			);

			if (inHeader) {
				// cancel the event propagation so it won't trigger the ClickOutsideSection in ControlsHeader
				event?.nativeEvent.stopImmediatePropagation();
			}
		},
		[dispatch, inHeader],
	);

	const pressed = visibleMenu === "participants" || visibleMenu === "participantOptions";
	const className = resolveClassName(styles, {
		buttonActive: pressed && inHeader,
		notification: showNotification && inHeader,
	});
	const supplementaryIcon = !inHeader ? "continue" : undefined;

	const participantButtonKeyboardShortcut = ["alt", "p"];
	const tooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.tooltip],
		participantButtonKeyboardShortcut,
	);

	const ariaLabel =
		strings[TokenNames.ariaLabel] + (showNotification ? " " + strings[TokenNames.notificationText] : "");

	const ariaLabelFormatted = useGetFormattedHotkeyString(ariaLabel, participantButtonKeyboardShortcut);

	return (
		<ControlButton
			id={participantsButtonId}
			icon={Participants}
			ariaLabel={tabString === undefined ? ariaLabelFormatted : ariaLabelFormatted + String(tabString)}
			onClick={onClick}
			ariaExpanded={pressed}
			buttonClassName={className}
			keyboardShortcut={participantButtonKeyboardShortcut}
			supplementaryIcon={supplementaryIcon}
			buttonText={!inHeader ? strings[TokenNames.label] : undefined}
			tooltipText={!noTooltip && !pressed ? tooltipFormatted : undefined}
		/>
	);
};

ParticipantsButton.displayName = "ParticipantsButton";

export default ParticipantsButton;
