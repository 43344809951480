/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Hardware test card contents
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.HardwareCardContents
 */

import React, { FC, PropsWithChildren, ReactElement } from "react";
import { useWindowSize } from "~/hooks";
import { useHardwareTestState } from "~/state";
import { DisplayContext, HardwareTestTab, ScreenWidthInflectionPoint } from "~/types";
import { resolveClassName } from "~/utils/className";
import CopyrightLinkButton from "../Copyright/CopyrightLinkButton";
import { HardwareSetupDisplayContext } from "../HardwareSetup/HardwareSetup";
import HardwarePreviewJoinOptions from "./HardwarePreviewJoinOptions";
import HardwarePreviewPanel from "./HardwarePreviewPanel";
import styles from "./HardwareTest.module.scss";
import SettingsPanel from "./SettingsPanel";
import WelcomeHeading from "./WelcomeHeading";

interface IProps {
	mainButton: ReactElement;
	isLobby?: boolean;
}

/**
 * The HardwareTest component
 *
 * Holds the card contents for the hardware test
 *
 * @param props the props ;)
 */
const HardwareCardContents: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { isLobby, mainButton } = props;

	const { width } = useWindowSize();

	const currentTab = useHardwareTestState((selectors) => selectors.getHardwareTestTab(), []);
	const panelClassName = resolveClassName(styles, {
		settingsPanelPlaceholder: true,
		panelClosed: currentTab.tab === HardwareTestTab.closed,
	});

	return (
		<>
			<div className={styles["scrollContainer"]}>
				<WelcomeHeading isLobby={isLobby} />
				<HardwareSetupDisplayContext.Provider
					value={isLobby ? DisplayContext.lobby : DisplayContext.standAlone}
				>
					<div className={styles["expandingContents"]}>
						<HardwarePreviewPanel />
						<SettingsPanel />
					</div>
					<div className={styles["expandingContents"]}>
						<HardwarePreviewJoinOptions isLobby={isLobby}>
							{width > ScreenWidthInflectionPoint && mainButton}
						</HardwarePreviewJoinOptions>
						{width > ScreenWidthInflectionPoint ? null : (
							<CopyrightLinkButton hardwareTestStyling />
						)}
						<div className={panelClassName} aria-hidden />
					</div>
				</HardwareSetupDisplayContext.Provider>
			</div>
			{width <= ScreenWidthInflectionPoint && (
				<div className={styles["mainButtonContainer"]}>{mainButton}</div>
			)}
		</>
	);
};

HardwareCardContents.displayName = "HardwareCardContents";

export default HardwareCardContents;
