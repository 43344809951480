/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file drop down menu page
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Menu.MenuPage
 */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import { useDispatch } from "@epic/react-redux-booster";
import React, { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import Chevron from "~/icons/chevron";
import { uiActions } from "~/state";
import { determineMenuActionType, useUIState } from "~/state/ui";
import { Menu } from "~/types";
import { resolveClassName } from "~/utils/className";
import { focusElement } from "~/utils/general";
import { controlButtonSelector } from "../Buttons/ControlButton";
import { moreOptionsSelector } from "../Buttons/MoreMenuButton";
import { useMaxMenuPageHeight } from "../hooks/useMaxMenuPageHeight";
import { baseMenuSelector } from "./BaseMenu";
import styles from "./Menu.module.scss";

/**
 * Props for MenuPage Component
 */
interface IProps {
	/** Whether or not the page is the active page */
	active: boolean;

	/** Whether or not the page is the next page to be rendered */
	nextUp: boolean;

	/** Whether or not the page is being used as the menu content while the dropdown opens or closes */
	collapseBackground: boolean;

	/** Menu's parent that should be returned to on back, if no the main menu */
	parentMenu?: Menu | null;

	/** Title of the page to display at the top of the menu */
	title?: string;

	/** Whether to use the page's default title or a custom one set from shared state */
	dynamicTitle?: boolean;

	/** Aria label for the back button */
	backButtonLabel?: string;
}

/**
 * The MenuPage component
 * @param props The props ;)
 * @param ref reference to the top level div
 */
const MenuPage = React.forwardRef<HTMLDivElement, PropsWithChildren<IProps>>((props, ref) => {
	const { active, nextUp, collapseBackground, parentMenu, title, dynamicTitle, backButtonLabel, children } =
		props;

	const dynamicMenuData = useUIState((selectors) => selectors.getDynamicMenuData(), []);
	const menuActionType = useUIState((selectors) => selectors.getMenuActionType(), []);
	const backButtonRef = useRef<HTMLButtonElement>(null);
	const dispatch = useDispatch();

	// when this page becomes active, focus the title
	useEffect(() => {
		if (active && !collapseBackground && backButtonRef.current) {
			const element: HTMLElement | null = backButtonRef.current;
			element?.focus();
		}
	}, [active, collapseBackground, menuActionType]);

	const onBackClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			const actionType = determineMenuActionType(event);

			// Allow setting null as the "back" menu to allow the back action to close the menu
			const menuToSwitch = parentMenu !== undefined ? parentMenu : "menu";
			dispatch(uiActions.toggleVisibleMenu({ menu: menuToSwitch, actionType }));
		},
		[dispatch, parentMenu],
	);

	// when the end of the list is focused, close the dropdown menu and jump focus to the first non-more options button
	const onEndFocused = useCallback(() => {
		dispatch(uiActions.toggleVisibleMenu({ menu: null }));
		const firstButtonSelector = `*:not(${baseMenuSelector}) > ${controlButtonSelector}:not(${moreOptionsSelector})`;
		focusElement(firstButtonSelector);
	}, [dispatch]);

	// determine how far we can extend the menu page before we need to require scrolling
	const maxPageHeight = useMaxMenuPageHeight();

	const effectiveTitle = (dynamicTitle && dynamicMenuData?.title) || title;

	const pageClassName = resolveClassName(styles, {
		menuPage: true,
		nextUp: nextUp,
		hidden: !active && !nextUp && !collapseBackground, // this only happens if a page has alwaysRender=true in useMenuPages
	});

	return (
		<div ref={ref} className={pageClassName} aria-hidden={!active} style={{ maxHeight: maxPageHeight }}>
			{(effectiveTitle || dynamicTitle) && (
				<button
					type="button"
					ref={backButtonRef}
					className={styles["backButton"]}
					onClick={onBackClick}
					aria-label={backButtonLabel || title}
				>
					<div tabIndex={-1}>
						<Chevron height={15} width={15} chevronDirection="left" aria-hidden />
						<span className={styles["title"]} aria-hidden>
							{effectiveTitle}
						</span>
					</div>
				</button>
			)}
			{children}
			<div onFocus={onEndFocused} tabIndex={0} />
		</div>
	);
});

MenuPage.displayName = "MenuPage";

export default React.memo(MenuPage);
