/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Shared state for the error card
 * @author Max Harkins
 * @module Epic.VideoApp.State.FeedbackSurvey
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { IClientToken } from "~/types";
import { FeedbackPageStep, FeedbackSurveyLoadingStatus, ISurvey } from "~/types/survey";

/// TYPES ///

export interface IFeedbackSurveyState {
	hasShownTimeoutWarning: boolean;
	surveyTimeoutTimestamp: number | null; // unix timestamp of when survey submissions become invalid. Components are responsible for handling warning
	feedbackStep: FeedbackPageStep;
	feedbackClientToken: IClientToken | null;
	feedbackSurveyQuestionnaire: ISurvey | null;
	feedbackSurveyLoadingStatus: FeedbackSurveyLoadingStatus;
}

/// INIT ///

export function getInitialState(): IFeedbackSurveyState {
	return {
		hasShownTimeoutWarning: false,
		surveyTimeoutTimestamp: null,
		feedbackStep: FeedbackPageStep.hidden, // Do not show the feedback step unless specified by client configuration
		feedbackClientToken: null,
		feedbackSurveyQuestionnaire: null,
		feedbackSurveyLoadingStatus: FeedbackSurveyLoadingStatus.notYetLoaded,
	};
}

/// REDUCERS ///
export function setHasShownTimeoutWarning(
	state: IFeedbackSurveyState,
	hasShown: boolean,
): IFeedbackSurveyState {
	return {
		...state,
		hasShownTimeoutWarning: hasShown,
	};
}

export function setSurveyTimeoutTimestamp(
	state: IFeedbackSurveyState,
	timer: number | null,
): IFeedbackSurveyState {
	return {
		...state,
		surveyTimeoutTimestamp: timer,
	};
}

export function setFeedbackStep(state: IFeedbackSurveyState, step: FeedbackPageStep): IFeedbackSurveyState {
	if (state.feedbackStep === step) {
		return state;
	}

	if (state.feedbackStep === FeedbackPageStep.completed || state.feedbackStep === FeedbackPageStep.hidden) {
		return {
			...state,
			feedbackStep: step,
			feedbackClientToken: null,
		};
	}

	return {
		...state,
		feedbackStep: step,
	};
}

export function setClientToken(
	state: IFeedbackSurveyState,
	newToken: IClientToken | null,
): IFeedbackSurveyState {
	// Always clear out the JWT once the survey has reached the completed or hidden state
	if (state.feedbackStep === FeedbackPageStep.completed || state.feedbackStep === FeedbackPageStep.hidden) {
		return { ...state, feedbackClientToken: null };
	}

	return { ...state, feedbackClientToken: newToken };
}

export function setFeedbackSurveyQuestionnaire(
	state: IFeedbackSurveyState,
	survey: ISurvey,
): IFeedbackSurveyState {
	return {
		...state,
		feedbackSurveyQuestionnaire: survey,
		feedbackSurveyLoadingStatus: FeedbackSurveyLoadingStatus.successfullyLoaded,
	};
}

export function setFeedbackLoadingState(
	state: IFeedbackSurveyState,
	loadingStatus: FeedbackSurveyLoadingStatus,
): IFeedbackSurveyState {
	return {
		...state,
		feedbackSurveyLoadingStatus: loadingStatus,
	};
}

/// SELECTORS ///

function getHasShownTimeoutWarning(state: IFeedbackSurveyState): boolean {
	return state.hasShownTimeoutWarning;
}

function getSurveyTimeoutTimestamp(state: IFeedbackSurveyState): number | null {
	return state.surveyTimeoutTimestamp;
}

function getFeedbackStep(state: IFeedbackSurveyState): FeedbackPageStep {
	return state.feedbackStep;
}

function getClientToken(state: IFeedbackSurveyState): IClientToken | null {
	return state.feedbackClientToken;
}

function getFeedbackSurveyQuestionnaire(state: IFeedbackSurveyState): ISurvey | null {
	return state.feedbackSurveyQuestionnaire;
}

function getFeedbackSurveyLoadingStatus(state: IFeedbackSurveyState): FeedbackSurveyLoadingStatus {
	return state.feedbackSurveyLoadingStatus;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setHasShownTimeoutWarning,
		setSurveyTimeoutTimestamp,
		setFeedbackStep,
		setClientToken,
		setFeedbackSurveyQuestionnaire,
		setFeedbackLoadingState,
	},
	selectors: {
		getHasShownTimeoutWarning,
		getSurveyTimeoutTimestamp,
		getFeedbackStep,
		getClientToken,
		getFeedbackSurveyQuestionnaire,
		getFeedbackSurveyLoadingStatus,
	},
});

store.addSharedState(builtState.sharedState, "feedbackSurvey");

export const {
	actionCreators: feedbackSurveyActions,
	useSharedState: useFeedbackSurveyState,
	sharedState: state,
} = builtState;
