/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Alert that confirms whether the moderator will remove a user from the visit or not
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.RemoveParticipantChoiceAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { alertActions, useAuthState } from "~/state";
import { IClientToken, IRemoveParticipantAlert } from "~/types";
import { makeRequest } from "~/utils/request";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IRemoveParticipantAlert;
}

enum TokenNames {
	errorMessage = "ErrorMessage",
}

export enum RemoveParticipantChoiceAlertTestIds {
	self = "RemoveParticipantChoiceAlert",
}

/**
 * RemoveParticipant Choice alert component
 *
 * Renders a confirmation message that the user intends to remove a remote participant.
 */
const RemoveParticipantChoiceAlert: FC<IProps> = (props) => {
	const { alert } = props;

	const dispatch = useDispatch();
	const identity = alert.alertData.identity;
	const guid = alert.alertData.guid ?? "";
	const clientToken = useAuthState((selectors) => selectors.getClientToken(), []);
	const strings = useStrings("RemoveParticipantChoiceAlert", Object.values(TokenNames));

	const confirm = useCallback(() => {
		async function removeFromCall(): Promise<void> {
			try {
				await removeParticipant(clientToken, identity, guid);
			} catch {
				dispatch(alertActions.postGenericAlert(strings[TokenNames.errorMessage]));
			}
		}

		if (identity) {
			void removeFromCall();
			dispatch(alertActions.clearAlert());
		} else {
			dispatch(alertActions.clearAlert());
		}
	}, [identity, clientToken, guid, dispatch, strings]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	return (
		<ChoiceAlertBase
			data-testid={RemoveParticipantChoiceAlertTestIds.self}
			alert={alert}
			confirm={confirm}
			cancel={cancel}
		/>
	);
};

function removeParticipant(clientToken: IClientToken | null, id: string, guid: string): Promise<void> {
	return makeRequest("/api/VideoCall/Remove", "POST", clientToken, { identity: id, guid: guid });
}

RemoveParticipantChoiceAlert.displayName = "RemoveParticipantChoiceAlert";

export default RemoveParticipantChoiceAlert;
