/**
 * @copyright Copyright 2020-2023 Epic Systems Corporation
 * @file JWT/Authorization utilities
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.Jwt
 */

import { AccessTokenType, IAccessTokenUpdate, IClientToken, IHardwareTestAuth } from "~/types";
import {
	configurationCookieKey,
	getCookie,
	jwtCookieKey,
	jwtExpirationCookieKey,
	jwtHardwareTestCookieKey,
	jwtHasRefreshCookieKey,
	setCookie,
	tokenTypeKey,
} from "./cookies";
import { minutesToMs, secondsToMs } from "./dateTime";

export interface ISessionCookieInfo {
	/** The session's client token */
	clientToken: IClientToken;
	/** The expiration instant of the session, if none is found in cookies this will be set to 1 hour */
	expirationInstant: number;
	/** Whether or not the session has a refresh token */
	hasRefreshToken: boolean;
}

const HARDWARE_TEST_COOKIE_EXPIRATION = minutesToMs(10);

/**
 * Set the hardware test JWT in cookies
 * @param clientToken the session token for calling web APIs (includes JWT and type)
 * @param sessionKey Session id of hardware test
 */
export function setHardwareTestClientTokenCookies(
	clientToken: IClientToken | undefined,
	sessionKey: string,
): void {
	if (!clientToken?.jwt || !sessionKey) {
		return;
	}
	setCookie(jwtHardwareTestCookieKey + sessionKey, clientToken.jwt, HARDWARE_TEST_COOKIE_EXPIRATION);
	setCookie(tokenTypeKey + sessionKey, clientToken.type, HARDWARE_TEST_COOKIE_EXPIRATION);
}

/**
 * Set the session's encrypted configuration in cookies
 * @param encryptedConfiguration the session's encrypted configuration
 * @param sessionKey session ID of the hardware test
 */
export function setHardwareTestConfigurationCookie(encryptedConfiguration: string, sessionKey: string): void {
	if (!encryptedConfiguration) {
		return;
	}
	setCookie(configurationCookieKey + sessionKey, encryptedConfiguration, HARDWARE_TEST_COOKIE_EXPIRATION);
}

/**
 * Get the hardware test JWT in cookies
 * @param sessionKey Session id of hardware test
 */
export function getHardwareTestClientTokenFromSessionCookie(sessionKey: string): IClientToken | null {
	if (!sessionKey) {
		return null;
	}
	const jwt = getCookie(jwtHardwareTestCookieKey + sessionKey);
	const type = getCookie(tokenTypeKey + sessionKey) as AccessTokenType;

	return { jwt, type: type || "evc" };
}

/**
 * Get the client token from cookies for a particular session
 * @param sessionKey the session to get the client token of
 * @returns the client token that is associated with sessionKey, if one exists
 */
function getClientTokenFromSessionCookie(sessionKey: string): IClientToken | null {
	if (!sessionKey) {
		return null;
	}

	const jwt = getCookie(jwtCookieKey + sessionKey);
	let type = getCookie(tokenTypeKey + sessionKey) as AccessTokenType;
	if (!type) {
		// Default to evc if no type was saved in the cookie
		type = "evc";
	}

	return { jwt, type: type };
}

/**
 * Get the JWT from cookies for a particular session
 * @param sessionKey the session to get the JWT for
 * @returns the JWT that is associated with sessionKey, if one exists
 */
export function getConfigFromSessionCookie(sessionKey: string): string {
	if (!sessionKey) {
		return "";
	}
	return getCookie(configurationCookieKey + sessionKey);
}

/**
 * Get the expiration instant of a session
 * @param sessionKey the session to get the expiration of
 * @returns the expiration instant of the session, or null if the expiration cookie didn't exist
 */
export function getSessionExpiration(sessionKey: string): number | null {
	if (!sessionKey) {
		return null;
	}
	const expirationInstantString = getCookie(jwtExpirationCookieKey + sessionKey);
	if (!expirationInstantString) {
		return null;
	}
	return parseInt(expirationInstantString, 10);
}

/**
 * Get whether or not a particular session has a refresh token
 * @param sessionKey the session that should be checked for a refresh token
 * @returns true if the session has a refresh token, false otherwise
 */
function getHasRefreshCookie(sessionKey: string): boolean {
	if (!sessionKey) {
		return false;
	}
	return getCookie(jwtHasRefreshCookieKey + sessionKey) === "true";
}

/**
 * Set the JWT in cookies for a particular session
 * @param clientToken - The session token for calling web APIs (includes JWT and type)
 * @param sessionKey the session that the JWT belongs to
 * @param expirationInstant instant that the JWT expires
 * @param hasRefreshToken whether or not the session has a refresh token
 * @param cookieExpiresIn duration that the cookie should expire in, if different than session expiration
 */
export function setSessionCookies(
	token: IClientToken | null,
	sessionKey: string,
	expirationInstant: number,
	canRefresh: boolean = false,
	encryptedConfiguration?: string,
	cookieExpiresIn?: number,
): void {
	if (!token || !sessionKey) {
		return;
	}

	// cookieExpiresIn is specified when expiring session cookies - use
	// that value if present, otherwise use the actual session expiration
	const expiresIn = cookieExpiresIn ?? expirationInstant - Date.now();

	// set all relevant session cookies together with the same expiration
	setCookie(jwtCookieKey + sessionKey, token.jwt, expiresIn);
	setCookie(tokenTypeKey + sessionKey, token.type || "evc", expiresIn);
	setCookie(jwtExpirationCookieKey + sessionKey, expirationInstant.toString(), expiresIn);
	setCookie(jwtHasRefreshCookieKey + sessionKey, canRefresh.toString(), expiresIn);
	if (encryptedConfiguration) {
		setCookie(configurationCookieKey + sessionKey, encryptedConfiguration, expiresIn);
	}
}

/**
 * Extend the cookie duration for cookies associated with a particular session
 * @param sessionKey the session that should be extended
 * @returns session information for the extended session
 */
export function tryExtendSessionCookies(sessionKey: string): ISessionCookieInfo | null {
	if (!sessionKey) {
		return null;
	}

	const clientToken = getClientTokenFromSessionCookie(sessionKey);
	if (!clientToken?.jwt) {
		return null;
	}

	let expirationInstant = getSessionExpiration(sessionKey);
	if (expirationInstant === null) {
		expirationInstant = Date.now() + minutesToMs(60);
	}

	const hasRefreshToken = getHasRefreshCookie(sessionKey);
	const encryptedConfiguration = getConfigFromSessionCookie(sessionKey);

	setSessionCookies(clientToken, sessionKey, expirationInstant, hasRefreshToken, encryptedConfiguration);

	return { clientToken, expirationInstant, hasRefreshToken };
}

/**
 * Expire a particular session's cookies
 * @param sessionKey the session that should be expired
 * @param preventReconnect whether or not the user should be prevented from reconnecting
 * @param canRefresh whether or not the session has a refresh token
 */
export function expireSessionCookies(
	sessionKey: string,
	preventReconnect: boolean,
	canRefresh: boolean = false,
): void {
	const clientToken = getClientTokenFromSessionCookie(sessionKey);
	const expirationInstant = getSessionExpiration(sessionKey);
	const encryptedConfiguration = getConfigFromSessionCookie(sessionKey);

	// If allowing for refresh, cookie can live for 20s more
	const cookieLifetime = preventReconnect ? 0 : secondsToMs(20);
	setSessionCookies(
		clientToken,
		sessionKey,
		expirationInstant ?? Date.now(),
		canRefresh,
		encryptedConfiguration,
		cookieLifetime,
	);
}

/**
 * Extract the token & type model from an auth response.
 *
 * @param response - The response containing the client-side access token
 * @returns - The ClientToken with the JWT and type metadata
 */
export function extractClientTokenFromResponse(
	response: IAccessTokenUpdate | IHardwareTestAuth,
): IClientToken | null {
	if (!response.jwt) {
		return null;
	}
	const tokenTypeToUse = (response.tokenType ?? "evc") as AccessTokenType;

	return { jwt: response.jwt, type: tokenTypeToUse };
}
