/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to get a function that will rebuild a session a JWT cookie
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Auth.UseRebuildSessionFromCookies
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useEffect, useRef } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import {
	authActions,
	combinedActions,
	errorPageActions,
	hardwareTestActions,
	roomActions,
	userActions,
} from "~/state";
import { IAuthorizationResults, IClientToken } from "~/types";
import { IUserPreferencesWithEncryption } from "~/types/user";
import { onMemoryLeakAffectedChromiumVersion } from "~/utils/browser";
import { configurationCookieKey, setCookie } from "~/utils/cookies";
import { minutesToMs } from "~/utils/dateTime";
import frameMessager from "~/utils/frameMessager";
import { I18n } from "~/utils/i18n";
import { ISessionCookieInfo, getSessionExpiration } from "~/utils/jwt";
import { configureClientLogging, debug, getDebugSettingsFromCookie } from "~/utils/logging";
import { makeRequest } from "~/utils/request";
import {
	decryptUserPreferences,
	getUserPreferencesKeyFromCookie,
	loadUserPreferences,
} from "~/utils/userPreferences";
import { useDisconnect } from "..";
import { getSkipHardwareTestGuardrails } from "../../utils/skipHardwareTestGuardrails";

export interface IUpdateAccessToken {
	(token: IClientToken, sessionID: string): void;
}

interface IRebuildSessionFromCookies {
	(
		sessionID: string,
		cookieSessionInfo: ISessionCookieInfo,
		updateAccessToken: IUpdateAccessToken,
	): Promise<void>;
}

/**
 * Hook to manage all dependencies of refreshing a live call to ensure that session remains accurate despite not having gone through the OAuth2
 * authentication workflow.
 *
 * @returns A function to turn the JWT stored in cookies into a valid session. Also calls web services needed to refresh the current session.
 */
export function useRebuildSessionFromCookies(): IRebuildSessionFromCookies {
	const dispatch = useDispatch();

	const disconnect = useDisconnect();
	const disconnectRef = useRef(disconnect);
	useEffect(() => {
		disconnectRef.current = disconnect;
	}, [disconnect]);

	//load the user's preferences from local storage
	const getUserPreferences = useCallback(
		async (sessionID: string, token: IClientToken) => {
			const userPreferencesKey = getUserPreferencesKeyFromCookie(sessionID);
			if (userPreferencesKey) {
				const userPreferences = loadUserPreferences(userPreferencesKey);
				dispatch(userActions.setUserKey(userPreferencesKey));
				if (getSkipHardwareTestGuardrails(userPreferencesKey)) {
					dispatch(hardwareTestActions.setDisplaySkipHardwareTestToggleInLobby(false));
				}
				try {
					if (userPreferences && userPreferences.encryptedDisplayName) {
						const encryptedUserPreferences: IUserPreferencesWithEncryption = {
							displayName: userPreferences.encryptedDisplayName,
						};
						const decryptedUserPreferences = await decryptUserPreferences(
							token,
							encryptedUserPreferences,
						);
						dispatch(roomActions.setLocalDisplayName(decryptedUserPreferences.displayName ?? ""));
					}

					if (userPreferences && userPreferences.preferredLocale) {
						await I18n.setLocale(userPreferences.preferredLocale, dispatch);
					}
				} catch {
					// Don't allow user preferences to cause the refreshing the call to fail
				}

				dispatch(userActions.setPreferences(userPreferences));
			}
		},
		[dispatch],
	);

	const refreshConfiguration = useCallback(
		async (token: IClientToken, sessionID: string) => {
			const response = await refreshConfigurationRequest(token, sessionID);
			const { clientConfiguration, encryptedConfiguration } = response;
			// Certain Chromium versions have a memory leak that prevents us from using background effects/blurring.
			const disableAllBackgrounds = await onMemoryLeakAffectedChromiumVersion();
			if (clientConfiguration && disableAllBackgrounds) {
				clientConfiguration.userPermissions.disableAllBackgrounds = true;
			}
			dispatch(combinedActions.setConfiguration(clientConfiguration));

			if (encryptedConfiguration) {
				return encryptedConfiguration;
			}

			return null;
		},
		[dispatch],
	);

	// rebuild an existing session from cookies left behind on refresh
	const rebuildSessionFromCookies: IRebuildSessionFromCookies = useCallback(
		async (sessionID, cookieSessionInfo, updateAccessToken) => {
			const { clientToken, expirationInstant, hasRefreshToken } = cookieSessionInfo;

			const now = Date.now();

			// If the session has expired, don't bother cleaning up.
			const msUntilExpire = expirationInstant - now;
			if (now > expirationInstant) {
				debug("Session expired");
				dispatch(
					errorPageActions.setErrorCard({
						message: ErrorTokenNames.refreshFailedBody,
					}),
				);
				disconnectRef.current(true);
				return;
			}

			// initiate clearing the previous disconnection to ensure the current user stays active and connected
			const clearDisconnectPromise = clearDisconnect(clientToken);

			// initiate web services asynchronously for configuration and preferences
			const getConfigPromise = refreshConfiguration(clientToken, sessionID);
			const getUserPrefPromise = getUserPreferences(sessionID, clientToken);

			// setup client logging, useClientLogging will actually send off requests
			const clientLoggingConfig = getDebugSettingsFromCookie();
			configureClientLogging(dispatch, clientLoggingConfig);

			if (hasRefreshToken && msUntilExpire > minutesToMs(5)) {
				//if it's not time to refresh the access token yet, queue it up
				//if we can't clear the disconnection or another required web service fails, this will never run.
				const timerId = setTimeout(
					updateAccessToken,
					msUntilExpire - minutesToMs(5), //refresh 5 minutes before access token expiration
					clientToken,
					sessionID,
				);
				dispatch(authActions.setRefreshTokenTimer(timerId));
			}

			// Handle all refresh web services calls in parallel.
			// If any of these fail and surface an error, we consider this a failure state
			try {
				const [encryptedConfig] = await Promise.all([
					getConfigPromise,
					getUserPrefPromise,
					clearDisconnectPromise,
				]);

				// If we received a new configuration value from our refresh, save it to the cookies as well so we can send it via a web request in the future
				if (encryptedConfig) {
					let expirationInstant = getSessionExpiration(sessionID);
					if (expirationInstant === null) {
						expirationInstant = Date.now() + minutesToMs(60);
					}
					setCookie(configurationCookieKey + sessionID, encryptedConfig, expirationInstant);
				}
			} catch (error) {
				debug("Error on refresh: ", error);
				dispatch(
					errorPageActions.setErrorCard({
						message: ErrorTokenNames.refreshFailedBody,
					}),
				);
				disconnectRef.current(true);
			}

			// Don't set the JWT or refresh the access token until we have verified our session-required web services have completed
			dispatch(authActions.setClientToken(clientToken));

			// indicate that authentication succeeded to any parent windows
			frameMessager.postMessage("Epic.Video.AuthSuccess");

			if (hasRefreshToken && msUntilExpire <= minutesToMs(5)) {
				//if the access token is going to expire in the next 5 minutes, refresh it.
				updateAccessToken(clientToken, sessionID);
			}
		},
		[refreshConfiguration, getUserPreferences, dispatch],
	);

	return rebuildSessionFromCookies;
}

/**
 * Web Request to retrieve configuration data
 *
 * @param clientToken - The client token for web apis
 * @returns - The configuration information we can not store in CosmosDB due to size
 */
async function refreshConfigurationRequest(
	clientToken: IClientToken,
	sessionID: string,
): Promise<IAuthorizationResults> {
	return makeRequest<IAuthorizationResults>(
		"/api/VideoCall/RefreshConfiguration",
		"GET",
		clientToken,
		undefined,
		{
			queryStringData: { sessionID },
		},
	);
}

/**
 * Attempts to clear a pending disconnection for complete and accurate LVV Logging in the case of a page refresh
 */
async function clearDisconnect(clientToken: IClientToken): Promise<void> {
	return makeRequest("/api/VideoCall/ClearDisconnect", "POST", clientToken);
}
