/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Hardware Test authentication hook
 * @author Matt Panico
 * @module Epic.VideoApp.Hooks.Auth.UseHardwareTestAuthentication
 */

import { IAction, useDispatch } from "@epic/react-redux-booster";
import { useEffect, useRef } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { combinedActions, errorPageActions, useAuthState } from "~/state";
import {
	AuthRequestParameters,
	FDIQueryParameters,
	IClientToken,
	IConfigurationUpdateDTO,
	IHardwareTestAuth,
	QueryParameters,
} from "~/types";
import { expireNonSessionCookies } from "~/utils/cookies";
import { I18n, getPreferredLocale } from "~/utils/i18n";
import {
	extractClientTokenFromResponse,
	getConfigFromSessionCookie,
	getHardwareTestClientTokenFromSessionCookie,
	setHardwareTestClientTokenCookies,
	setHardwareTestConfigurationCookie,
} from "~/utils/jwt";
import { debug } from "~/utils/logging";
import { removeAllQueryParamsExcept, setSessionID } from "~/utils/queryParameters";
import { makeRequest } from "~/utils/request";
import { VideoVendor } from "~/web-core/types";
import { useCaseInsensitiveSearchParam, useDisconnect } from "..";

export function useHardwareTestAuthentication(): void {
	const dispatch = useDispatch();
	const clientToken = useAuthState((selectors) => selectors.getClientToken(), []);

	const sessionID = useCaseInsensitiveSearchParam(QueryParameters.sessionId) ?? "";
	const sessionIDRef = useRef(sessionID);

	// organization ID
	const organizationID = useCaseInsensitiveSearchParam(FDIQueryParameters.orgId) ?? "";
	const org = useCaseInsensitiveSearchParam(FDIQueryParameters.orgIdShort) ?? "";
	const orgID = organizationID || org;

	// User type
	const fullUserType = useCaseInsensitiveSearchParam(FDIQueryParameters.userTypeLong) ?? "";
	const UT = useCaseInsensitiveSearchParam(FDIQueryParameters.userType) ?? "";
	const userType = fullUserType || UT;

	// launch token
	const launchToken = useCaseInsensitiveSearchParam(FDIQueryParameters.launchToken) ?? "";

	// Telehealth token
	const ttLong = useCaseInsensitiveSearchParam(FDIQueryParameters.telehealthTokenLong) ?? "";
	const tt = useCaseInsensitiveSearchParam(FDIQueryParameters.telehealthToken) ?? "";
	const telehealthToken = ttLong || tt;

	// Web Pacs
	const encryptedString = useCaseInsensitiveSearchParam(FDIQueryParameters.encryptedString) ?? "";
	const useSecondary = useCaseInsensitiveSearchParam(FDIQueryParameters.useSecondaryEncryptionKey) === "1";

	// Demo system
	const computerName = useCaseInsensitiveSearchParam(FDIQueryParameters.demoSystemComputerName) ?? "";
	const majorVersion = useCaseInsensitiveSearchParam(FDIQueryParameters.demoSystemMajorVersion) ?? "";

	const disconnect = useDisconnect();
	const disconnectRef = useRef(disconnect);
	useEffect(() => {
		disconnectRef.current = disconnect;
	}, [disconnect]);

	// if we don't have a valid JWT, get one
	useEffect(() => {
		if (clientToken !== null) {
			return;
		}

		// Remove any cookies from a video call that might hang around
		expireNonSessionCookies(sessionIDRef.current);

		// If we don't have a valid JWT, check the cookies. If nothing in the cookies, time to validate a new request
		const cookieToken = getHardwareTestClientTokenFromSessionCookie(sessionIDRef.current);
		if (cookieToken) {
			removeAllQueryParamsExcept([QueryParameters.sessionId]);
			void rebuildHardwareTestSessionFromCookies(cookieToken, sessionIDRef.current, dispatch);
			return;
		}

		// Build the API request parameters based on which URL values were available
		const requestParams = exportFunctions.buildHardwareTestAuthenticationRequest(
			orgID,
			encryptedString,
			useSecondary,
			launchToken,
			telehealthToken,
			userType,
			computerName,
			majorVersion,
		);

		// Authenticate with the server for a JWT. If there is an error with the request, treat it as a validation error
		const newSessionID = setSessionID();
		loadJWT(requestParams)
			.then(async (response: IHardwareTestAuth) => {
				removeAllQueryParamsExcept([QueryParameters.sessionId]);

				setHardwareTestClientTokenCookies(
					extractClientTokenFromResponse(response) ?? undefined,
					newSessionID,
				);
				await setLocaleAndJWT(response, newSessionID, dispatch);
			})
			.catch((error: Error) => {
				debug("Unable to complete authorization", error);
				dispatch(
					errorPageActions.setErrorCard({
						message: ErrorTokenNames.standaloneMessage,
					}),
				);
				disconnectRef.current(true);
			});
	}, [
		orgID,
		encryptedString,
		useSecondary,
		launchToken,
		telehealthToken,
		userType,
		computerName,
		majorVersion,
		dispatch,
		clientToken,
	]);
}

/**
 * Packages up the URL parameters for use in the API call. Maps keys in the query string
 * to what is expected on the server. Used to send the encrypted string version of call context.
 *
 * @param org - Organization ID for the query string
 * @param encString - Encrypted string that holds launch model parameters
 * @param useSecondary - Which encryption key to use when validating parameters
 * @param launch - The launch token for the authentication request
 * @param telehealthToken - The telehealth token for the authentication request
 * @param userType - Type of the user
 * @param computerName -Computer name
 * @param majorVersion - Major version
 *
 * @returns The request object with the proper parameters from the URL
 */
function buildHardwareTestAuthenticationRequest(
	org: string,
	encString: string,
	useSecondary: boolean,
	launch: string,
	telehealthToken: string,
	userType: string,
	computerName: string,
	majorVersion: string,
): Record<string, string> {
	const params: Record<string, string> = {};

	params[AuthRequestParameters.orgId] = org;
	params[AuthRequestParameters.useSecondaryEncryptionKey] = useSecondary ? "1" : "";

	if (encString) {
		params[AuthRequestParameters.encryptedString] = encString;
	}

	if (launch) {
		params[AuthRequestParameters.launchToken] = launch;
	}

	if (telehealthToken) {
		params[AuthRequestParameters.telehealthToken] = telehealthToken;
	}

	if (userType) {
		params[AuthRequestParameters.userType] = userType;
	}

	if (computerName) {
		params[AuthRequestParameters.demoSystemComputerName] = computerName;
	}

	if (majorVersion) {
		params[AuthRequestParameters.demoSystemMajorVersion] = majorVersion;
	}

	return params;
}

/**
 * This is needed for unit tests to not fail
 * Javascript compilation says createBodyRequest is not called
 * unless it is exported and called this way
 */
const exportFunctions = {
	buildHardwareTestAuthenticationRequest,
	useHardwareTestAuthentication,
};

export default exportFunctions;

/**
 * Loads and returns a jwt token if the user is authenticated
 */
async function loadJWT(params: Record<string, string>): Promise<IHardwareTestAuth> {
	return makeRequest<IHardwareTestAuth>("/api/Auth/HardwareTest", "GET", null, undefined, {
		queryStringData: params,
	});
}

/**
 * Web Request to retrieve configuration data
 *
 * @param clientToken - The session token for calling web APIs
 * @returns - The configuration information we can not store in CosmosDB due to size
 */
async function refreshConfigurationRequest(
	clientToken: IClientToken,
	sessionID: string,
): Promise<IConfigurationUpdateDTO> {
	return makeRequest<IConfigurationUpdateDTO>(
		"/api/TestMode/RefreshConfiguration",
		"GET",
		clientToken,
		undefined,
		{
			queryStringData: { sessionID },
		},
	);
}

/**
 * Set the current locale and the JWT into shared state
 * @param authResponse the JWT and configuration that should be set into shared state
 * @param sessionID the user's current session ID
 * @param dispatch the dispatch function, used to modify shared state
 * @param vendorOverride the vendor to use for the standalone hardware test. Overrides the client configuration value
 */
async function setLocaleAndJWT(
	authResponse: IHardwareTestAuth,
	sessionID: string,
	dispatch: <T extends IAction>(action: T) => T,
	vendorOverride?: VideoVendor,
): Promise<void> {
	// prefer cookie value, then browser locale
	const locale = getPreferredLocale();
	await I18n.setLocale(locale, dispatch);

	if (authResponse?.encryptedConfiguration) {
		setHardwareTestConfigurationCookie(authResponse?.encryptedConfiguration, sessionID);
	}

	if (vendorOverride) {
		authResponse.vendorOverride = vendorOverride;
	}

	dispatch(combinedActions.setHardwareTestJwtAndConfig(authResponse));
}

/**
 * Reconstruct a hardware test session from cookies by refreshing configuration and resetting the JWT
 * @param cookieJWT the JWT retrieved from cookies
 * @param sessionID the user's current session ID
 * @param dispatch the dispatch function, used to modify shared state
 */
async function rebuildHardwareTestSessionFromCookies(
	cookieToken: IClientToken,
	sessionID: string,
	dispatch: <T extends IAction>(action: T) => T,
): Promise<void> {
	let response: IConfigurationUpdateDTO | null = null;
	let vendorOverride: VideoVendor | undefined = undefined;

	if (getConfigFromSessionCookie(sessionID)) {
		try {
			response = await refreshConfigurationRequest(cookieToken, sessionID);
		} catch {
			// If refresh config fails, response will be null and we will use the vendor override
		}
	}

	// Hard-code the vendor to Twilio if there is no configuration so that the user can still test their hardware
	// We expect this not to be necessary after deprecating WebPacs in early September.
	if (response == null) {
		vendorOverride = VideoVendor.twilio;
	}

	await setLocaleAndJWT(
		{ ...response, jwt: cookieToken.jwt, tokenType: cookieToken.type },
		sessionID,
		dispatch,
		vendorOverride,
	);
}
