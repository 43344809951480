/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to get a function that will audit the use of a feature
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.Auditing.UseAuditFeatureUse
 */

import { useCallback } from "react";
import { useAuthState } from "~/state";
import { EventFlag, EventType, IClientToken } from "~/types";
import { makeRequest } from "~/utils/request";

type FeatureAudit = AuditEvent[];
/**
 * Get a function to audit feature use
 * @returns a function to audit feature usage
 */
export function useAuditFeatureUse(): (featureInput: FeatureAudit) => Promise<void> {
	const clientToken = useAuthState((selectors) => selectors.getClientToken(), []);

	const auditUse = useCallback(
		async (audit: FeatureAudit) => {
			// if room.isDisconnecting is used to not log events, ensure it works with screen sharing stopped
			// being audited in useScreenShareTrackActions.removeScreenShareTrack when called via useDisconnect
			if (!clientToken) {
				return;
			}

			try {
				await auditFeatureUse(clientToken, audit);
			} catch {
				// don't allow feature use auditing to crash the client
			}
		},
		[clientToken],
	);

	return auditUse;
}

interface IAuditParams {
	/** timestamp that the action occurred, defaults to current time */
	timestamp?: number;
	/** The unique participant identifier that an event targets. This should correspond the the Twilio identity field (session.UniqueUserId) */
	targetParticipantId?: string;
}

type AuditEvent = IBasicAuditEvent | IScreenShareAuditEvent;

export interface IBasicAuditEvent extends IAuditParams {
	feature: EventType;
}

export interface IScreenShareAuditEvent extends IBasicAuditEvent {
	feature: EventType.screenSharingStarted | EventType.screenSharingStopped;
	eventFlags: EventFlag.hasScreenShareAudio[];
}

/**
 * Make a web server call to audit a feature use for the current user
 * @param clientToken - The session token for calling web APIs
 * @param feature feature to audit
 * @param params additional details about the feature being audited
 */
async function auditFeatureUse(clientToken: IClientToken, audit: FeatureAudit): Promise<void> {
	return makeRequest("/api/VideoCall/AuditFeatureUse", "POST", clientToken, { events: audit });
}
