/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Logging utilities
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.Logging
 */

import { IAction } from "@epic/react-redux-booster";
import { Logger } from "twilio-video";
import { DebuggingLogLevel, roomActions } from "~/state/room";
import { IClientLoggingConfig, IClientToken, LogTargetFile } from "~/types";
import { clientLoggingCookieKey, expireCookie, getCookie, setCookie } from "./cookies";
import { hoursToMs } from "./dateTime";
import { isDevEnvironment, stringToBuffer } from "./general";
import { makeRequest } from "./request";

interface ISaveLogsResponse {
	success: boolean;
}

let debuggingLogLevel = DebuggingLogLevel.none;
let needsFlush = false;
const LOGS: string[] = [];
const webRtcInternals: string[] = [];
type LogSource = "Twilio" | "EVC";
const DELIMITER = "\r\n";

/**
 * Output a message to the console only when running in development mode, and log to the client logging if necessary
 *
 * @export
 * @param message The message to output
 * @param optionalParams Additional objects to output
 */
export function debug(message?: unknown, ...optionalParams: unknown[]): void {
	addLogEntryBase("EVC", "debug", false, [new Date().toISOString(), message, ...optionalParams]);
}

/**
 * Output a message to the console, and log to the client logging if necessary
 *
 * @export
 * @param message The message to output
 * @param optionalParams Additional objects to output
 */
export function log(message?: unknown, ...optionalParams: unknown[]): void {
	addLogEntryBase("EVC", "info", false, [new Date().toISOString(), message, ...optionalParams]);
}

/**
 * Output a warning to the console, and log to the client logging if necessary
 *
 * @export
 * @param message The message to output
 * @param optionalParams Additional objects to output
 */
export function warn(message?: unknown, ...optionalParams: unknown[]): void {
	addLogEntryBase("EVC", "warn", false, [new Date().toISOString(), message, ...optionalParams]);
}

/**
 * Output a message to the console only when running in development mode, and log to the client logging if necessary, but don't force the client logging to flush
 *
 * @export
 * @param message The message to output
 * @param optionalParams Additional objects to output
 */
export function debugNoFlush(message?: unknown, ...optionalParams: unknown[]): void {
	addLogEntryBase("EVC", "debug", true, [new Date().toISOString(), message, ...optionalParams]);
}

/**
 * Configure the Twilio logger
 */
export function configureClientLogging(
	dispatch: <T extends IAction>(action: T) => T,
	loggingConfig?: IClientLoggingConfig,
): void {
	const logger = Logger.getLogger("twilio-video");

	logger.methodFactory = () => {
		return (...infoToLog: any[]) => addTwilioLogEntry(...infoToLog);
	};

	if (!loggingConfig || loggingConfig.logLevel === null) {
		logger.setLevel("warn");
		expireCookie(clientLoggingCookieKey);
		return;
	}

	storeDebugSettingsInCookie(loggingConfig);

	if (loggingConfig.logLevel === "debug" || loggingConfig.logLevel === "DEBUG") {
		debuggingLogLevel = DebuggingLogLevel.verbose;
	} else {
		debuggingLogLevel = DebuggingLogLevel.low;
	}

	const { logLevel, interval } = loggingConfig;

	// set this to a low level of logging, addTwilioLogEntry will prevent anything
	// lower than "warn" from being logged to the console
	logger.setLevel(logLevel ?? "info");

	// set the logging interval in shared state
	dispatch(roomActions.setClientLoggingInterval(interval));
	dispatch(roomActions.setDebuggingLogLevel(debuggingLogLevel));
}

/**
 * Send webRtc logs to the server to be saved in azure blob
 * @param clientToken - The session token for calling web APIs
 * @param data string of webRtc internals data
 */
export async function saveWebRtcLogs(clientToken: IClientToken, data: string): Promise<void> {
	if (debuggingLogLevel !== DebuggingLogLevel.verbose) {
		return;
	}
	webRtcInternals.push(data);
	const success = await saveLogs(clientToken, webRtcInternals, LogTargetFile.webRTCLogs);

	if (success) {
		webRtcInternals.splice(0);
	} else if (webRtcInternals.length > 4) {
		// make sure the local array doesn't get large enough that there are performance concerns
		webRtcInternals.shift();
	}
}

/**
 * Add a log entry
 * @param {LogSource} source the source of the log entry
 * @param {string} logLevel the importance of this log entry
 * @param {boolean} noFlush If this data does not need be logged to the server immediately
 * @param {any[]} infoToLog all of the information that should be included in the entry
 */
function addLogEntryBase(source: LogSource, logLevel: string, noFlush: boolean, ...infoToLog: any[]): void {
	const logLevelLowerCase = logLevel.toLowerCase();
	let otherLogLevel = false;
	switch (logLevelLowerCase) {
		case "debug":
			if (debuggingLogLevel === DebuggingLogLevel.verbose || isDevEnvironment()) {
				console.debug(infoToLog);
			}
			break;
		case "warn":
			console.warn(infoToLog);
			break;
		case "error":
			console.error(infoToLog);
			break;
		default:
			otherLogLevel = true;
			break;
	}
	if (debuggingLogLevel === DebuggingLogLevel.none) {
		return;
	}
	if (otherLogLevel) {
		console.log(infoToLog);
	}
	if (debuggingLogLevel === DebuggingLogLevel.low && logLevelLowerCase === "debug") {
		return;
	}
	LOGS.push(`${source} (${logLevel}): ` + infoToLog.map(convertLogInfoToString).join(" "));
	if (!noFlush) {
		needsFlush = true;
	}
}

/**
 * Figures out how to safely convert a log entry to a string
 * @param data array of strings to combine
 * @param delimiter delimiter
 * @returns string delimited and post-fixed with delimiter, or the empty string if the array is empty
 */
function stringArrayToDelimitedString(data: string[], delimiter: string): string {
	if (data.length < 1) {
		return "";
	}
	return data.join(delimiter) + delimiter;
}

/**
 * Figures out how to safely convert a log entry to a string
 * @param {any} entry the entry to attempt to stringify
 */
function convertLogInfoToString(entry: any): string {
	if (typeof entry === "object") {
		try {
			return JSON.stringify(entry, (key, value) => {
				if (key.substring(0, 7) === "__react") {
					return `${(entry as HTMLElement).nodeName}#${(entry as HTMLElement).id}`;
				}
				if (key !== "" && typeof value === "object" && entry === value) {
					return "[Circular]";
				}
				return value; // eslint-disable-line @typescript-eslint/no-unsafe-return
			});
		} catch (error) {
			console.log(error);
			return "[object serialization error]";
		}
	}
	if (typeof entry === "string") {
		return entry;
	}
	return entry.toString(); // eslint-disable-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
}

/**
 * Add a log entry from Twilio's logger to our log entries for the call
 * @param {Log.LoggingMethod} originalLogger function to perform Twilio's default logging to the console
 * @param {any[]} infoToLog array of information that should be logged, based on docs this data should be [dateTimeIso, logLevel, component, message, data]
 */
function addTwilioLogEntry(...infoToLog: any[]): void {
	const infoForInternalLogs = infoToLog.slice();
	const [logLevel] = infoForInternalLogs.splice(1, 1); // eslint-disable-line @typescript-eslint/no-unsafe-assignment

	if (typeof logLevel !== "string") {
		return;
	}

	// Twilio VideoProcessor object breaks JSON.stringify due to the length of string produced, so make sure we don't include those objects to log
	// Filter out unnecessary processor data; looping through and deleting all "processor" properties or checking object size is too expensive, causing freezes
	// Mentioned at https://github.com/twilio/twilio-video-processors.js/issues/27
	// Ideally this code can be removed once the issue has been resolved.
	if (typeof infoForInternalLogs[2] === "string") {
		const message = infoForInternalLogs[2];
		if (
			message.includes("VideoProcessor") ||
			message.includes("Options") ||
			message.includes("LocalTracks") ||
			message.includes("VideoTrack") ||
			message.includes("Room")
		) {
			// Splices data since we've already spliced out the logLevel
			infoForInternalLogs.splice(3, 3);
		}
	}

	addLogEntryBase("Twilio", logLevel, false, ...infoForInternalLogs);
}

/**
 * Add a custom log entry
 * @param {string} logLevel the importance of this log entry
 * @param {any[]} infoToLog array of information that should be logged
 */
export function addLogEntry(logLevel: string, ...infoToLog: any[]): void {
	addLogEntryBase("EVC", logLevel, false, ...infoToLog);
}

/**
 * Get the accumulated log entries
 * @returns {string[]} the log entries that have been accumulated since the last call
 */
function getLogEntries(): string[] {
	return LOGS.splice(0);
}

/**
 * Save any client logs that have been stored since the last call
 * @param clientToken - The session token for calling web APIs
 */
export async function saveClientLogs(clientToken: IClientToken): Promise<boolean> {
	if (!needsFlush) {
		return true;
	}
	return saveLogs(clientToken, getLogEntries(), LogTargetFile.clientLogs);
}

/**
 * Send logEntries to the server, indicating if they are webRtcData or client logs data
 * @param clientToken - The session token for calling web APIs
 * @param logEntries string array of log data to be saved
 * @param logTarget target file for log entries
 */
async function saveLogs(
	clientToken: IClientToken,
	logEntries: string[],
	logTarget: LogTargetFile,
): Promise<boolean> {
	if (logEntries.length < 1) {
		return true;
	}
	if (logTarget === LogTargetFile.clientLogs) {
		needsFlush = false;
	}

	const stringDelimitedData = stringArrayToDelimitedString(logEntries, DELIMITER);

	try {
		const response = await makeRequest<ISaveLogsResponse>(
			"/api/Debug/LogDebugData",
			"POST",
			clientToken,
			stringToBuffer(stringDelimitedData),
			{
				queryStringData: { logTarget },
				contentType: "application/octet-stream",
			},
		);
		return response.success;
	} catch {
		// makeRequest will handle logging the API error, add another entry for how many entries failed
		addLogEntry("warn", `Error logging last ${logEntries.length} entries`);
		LOGS.unshift(...logEntries);
		return false;
	}
}

/**
 * Stores debug settings in a cookie to persist after refresh
 * @param {IClientLoggingConfig} loggingConfig the config to store
 */
export function storeDebugSettingsInCookie(loggingConfig: IClientLoggingConfig): void {
	setCookie(clientLoggingCookieKey, JSON.stringify(loggingConfig), hoursToMs(4));
}

/**
 * Loads debug settings from a cookie after refresh
 * @returns {IClientLoggingConfig} the config that was stored
 */
export function getDebugSettingsFromCookie(): IClientLoggingConfig | undefined {
	const loggingConfig = getCookie(clientLoggingCookieKey);
	if (loggingConfig) {
		return JSON.parse(loggingConfig) as IClientLoggingConfig;
	}
	return undefined;
}
