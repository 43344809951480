/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Types used during the authentication process
 * @author Will Cooper
 * @module Epic.VideoApp.Types.Auth
 */

import { DebuggingLogLevel } from "~/state/room";
import { VideoVendor } from "~/web-core/types";
import { IClientConfigurationDTO } from "./user";

/** Valid FDI query parameters and our server's auth endpoint parameters */
export enum AuthRequestParameters {
	encryptedString = "encryptedString",
	orgId = "organizationID",
	useSecondaryEncryptionKey = "secondaryKey",
	launchToken = "launch",
	userType = "UT",
	telehealthToken = "TT",
	demoSystemComputerName = "cn",
	demoSystemMajorVersion = "mv",
	useLowBandwidthMode = "LowBandwidth",
	userId = "userId",
	conferenceID = "conferenceId",
	captureClientLogs = "captureLogs",
	setName = "setName",
	skipHardwareTest = "skipHardwareTest",
}

/** Alternative FDI query parameters that can be used in place of parameters from AuthRequestParameters */
enum AlternativeFDIParameters {
	orgIdShort = "org",
	telehealthTokenLong = "telehealthToken",
	useSecondaryEncryptionKeyShort = "sKey",
	userTypeLong = "userType",
}

/**
 * All valid FDI query parameters, combining those directly passed through to auth endpoints and their alternatives
 */
export type FDIQueryParameters = AuthRequestParameters | AlternativeFDIParameters;
export const FDIQueryParameters = { ...AuthRequestParameters, ...AlternativeFDIParameters };

// Server response from authorization calls
export interface IAuthorizationResults extends IAccessTokenUpdate {
	conferenceDateIso: string | null;
	displayName: string | null;
	encryptedDisplayName: string | null;
	userKey: string;
	logLevel: DebuggingLogLevel;
}

/**
 * Reasons that authentication/validation may fail for the video client (keep in sync with AuthFailureReason in VideoCallAuthException.cs)
 * Not an exhaustive list, but should include reasons that will be communicated to users on the error page
 */
export enum AuthFailureReason {
	unknown = 0,
	tooEarly = 1,
	tooLate = 2,
	removed = 3,
}

export interface IAuthFailure {
	failureReason: AuthFailureReason;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function instanceOfIAuthFailure(object: any): object is IAuthFailure {
	return "failureReason" in object;
}

export interface IConfigurationUpdateDTO {
	clientConfiguration: IClientConfigurationDTO;
	encryptedConfiguration: string;
}

export interface IAccessTokenUpdate extends IConfigurationUpdateDTO {
	jwt: string;
	tokenType: string;
	expirationSeconds: number;
	hasRefreshToken: boolean;
	useLowBandwidthMode: boolean;
}

export interface IHardwareTestAuth extends Partial<IConfigurationUpdateDTO> {
	jwt: string;
	vendorOverride?: VideoVendor; // Used to override the vendor configured in client configuration
	tokenType: string;
}

export interface IDisplayNameResponse {
	displayName: string;
}

// Keep in sync with TypedAccessToken.cs on the web server
export type AccessTokenType = "evc" | "vinz";

/**
 * Interface for client side tokens used to communicate to Epic Video Client or other backend services.
 * Includes a JWT (JSON Web Token) and meta data on the token.
 */
export interface IClientToken {
	type: AccessTokenType;
	jwt: string;
}
